import { GLTFExporter }  from "three/examples/jsm/exporters/GLTFExporter.js";
import {download} from "@/utils/downLoad";
import { saveAs } from '@/three/FileSaver';
const GLTFUtil = {
    // 导出gltf
    exporterGltf(scene) {
        let igltfexporter = new GLTFExporter();
// 导出gltf模型配置：
        const options = {
            //true导出位置、缩放、旋转变换，false导出节点的矩阵变换
            trs: false,
            //是否只导出可见的
            onlyVisible: true,
            truncateDrawRange: true,
            //是否二进制，true导出glb模型，false导出gltf模型
            binary: false,
            //最大贴图尺寸
            maxTextureSize: Infinity,
        };

        igltfexporter.parse(
            scene,
            function (result) {
                //result即是gltf文件，写入到本地

                download("scene.gltf", result);
            },
            options
        );
    },
    exporterGlb(scene) {
        let igltfexporter = new GLTFExporter();
        const options = {
            trs: false,
            onlyVisible: true,
            truncateDrawRange: true,
            binary: true,
            maxTextureSize: Infinity,
        };
        igltfexporter.parse(
            scene,function (result) {
                if (result instanceof ArrayBuffer) {
                    //result即为glb模型
                    const blob = new Blob([result], { type: 'model/gltf-binary' });
                    saveAs(blob, 'model.glb');
                }
            },
            options
        );
    }
}
export default GLTFUtil;
