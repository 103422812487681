<template>
  <div class="sectionBox leftMeterTable" :style="{ backgroundImage: 'url(' + secBackground + ')' }">
    <h3 style="text-align: center;font-size: .9rem;
            margin-top: 0.2rem;">水表日用量</h3>
    <!-- <el-table :data="waterList" style="width: 100%"  max-height="250">
      <el-table-column type="index">
      </el-table-column>
      <el-table-column prop="METER_NAME" label="位置">
      </el-table-column>
      <el-table-column prop="DAY_USE_SUM" label="用量(m³)">
      </el-table-column>
      <el-table-column prop="MAX_READ_SUM" label="读数(m³)">
      </el-table-column>
    </el-table> -->
    <table>
      <thead>
        <th style="width: 16%; text-align: right">序号</th>
        <th style="width: 40%">位置</th>
        <th style="width: 20%;text-align: left">用量(m³)</th>
        <th style="width: 24%;text-align: left">读数(m³)</th>
      </thead>
    </table>
    <vue-seamless-scroll :data="waterList" :class-option="optionHover" class="seamless-warp" v-if="waterList[0]">
      <ul>
        <li v-for="(item, idx) in waterList" class="liStyle" v-on:click="itemclick(item.WATER_METER_ID)">
          <span style=" width: 10%;text-align: center">{{ idx + 1 }}</span>
          <span style="width: 40%;text-align: center">{{ item.METER_NAME }}</span>
          <span style="width: 25%;text-align: center">{{ item.DAY_USE_SUM }}</span>
          <span style="width:25%;text-align: center">{{ item.MAX_READ_SUM }}</span>
        </li>
      </ul>
    </vue-seamless-scroll>
  </div>
</template>

<script>
import $ from "@/assets/js/jquery.min";
import { getDate } from "../utils/dateUtil";
import vueSeamlessScroll from "../utils/vue-seamless-scroll";

export default {
  name: "meterUseDemo",
  components: {
    vueSeamlessScroll,
  },
  data() {
    return {
      USER_ID: "dfe5d01baf1c485286fead0d34f805bd",
      url: "http://water.jicyun.com:8071",
      TOKEN: "b17a005fb05a4fa9b115aa04fc3ad0e9",
      secBackground: require("../assets/images/indexYSQS.16c2127c.png"),
      waterList: []
    };
  },
  mounted() {
  },
  computed: {
    //滚动表格的配置项
    optionHover() {
      return {
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        step: 0.5, // 数值越大速度滚动越快
        openWatch: true, // 开启数据实时监控刷新dom
        // limitMoveNum: 5, //开始滚动的数据量
        // singleHeight: 0, //单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, //单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        // waitTime: 1000 //单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {
    codeInit(user) {
      console.log(user)
      this.USER_ID = user.USER_ID || this.USER_ID;
      this.url = user.ip || this.url;
      this.TOKEN = user.TOKEN || this.TOKEN;
      this.showData();
    },
    itemclick(waterId) {
      console.log(waterId);
      this.$emit("lookMeter", waterId);
    },
    showData() {
      this.getdata();
    },
    // 方法一
    // 加载显示设备状态、用水曲线
    getdata() {
      var that = this;
      $.get(
        that.url + "/appWaterMeter/meterList",
        {
          USER_ID: that.USER_ID,
          TOKEN: that.TOKEN,
        },
        function (data) {
          if (data.INFO == "success") {
            that.getTimedata(data.meterList);
          } else {
            console.log("token失效");
          }
        }
      );
    },
    getTimedata(meterList) {
      var that = this;
      $.get(
        that.url + "/apphome//meterDayDataList",
        {
          USER_ID: that.USER_ID,
          TOKEN: that.TOKEN,
          START_DATA_DATE: getDate("yy-MM-dd", 0),
        },
        function (data) {
          if (data.INFO == "success") {
            if (data.INFO != "登陆异常，请重新登陆") {
              if (data.INFO == "查询结果为空") {
                meterList.forEach(function (items) {
                  items.DAY_USE_SUM = "0.00";
                  items.MAX_READ_SUM = items.VALUE;
                  if (items.METER_NAME.length > 10)
                    items.METER_NAME = items.METER_NAME.substring(0, 10) + "...";
                });
              } else {
                var values = Object.values(data.waterTimeMap);
                meterList.forEach(function (items) {
                  items.DAY_USE_SUM = that.getDataUse(
                    items.WATER_METER_ID,
                    "DAY_USE_SUM",
                    values
                  );
                  items.MAX_READ_SUM = that.getDataUse(
                    items.WATER_METER_ID,
                    "MAX_READ_SUM",
                    values
                  );
                  if (items.METER_NAME.length > 10)
                    items.METER_NAME = items.METER_NAME.substring(0, 8) + "...";
                });
              }
              that.waterList = meterList;
            }
          } else {
            console.log("token失效");
          }
        }
      );
    },
    getDataUse(ID, TYPE, data) {
      let type = "0.00";
      data.forEach(function (items) {
        items = items[0];
        if (ID == items.WATER_METER_ID) {
          if (TYPE == "DAY_USE_SUM") {
            type = items.DAY_USE_SUM.toFixed(2);
          } else if (TYPE == "MAX_READ_SUM") {
            type = items.MAX_READ_SUM;
          }
        }
      });
      return type;
    },
    getDateYMH(days) {
      let yesterday = new Date(new Date().setDate(new Date().getDate() - days));
      yesterday =
        yesterday.getFullYear() +
        "-" +
        (yesterday.getMonth() + 1) +
        "-" +
        yesterday.getDate();
      console.log(yesterday);
      return yesterday;
    },
  },
};
</script>

<style lang='less' scoped>
// table {
//   width: 100%;
//   height: 100%;
//   // background-image: url("../assets/images/indexYSQS.16c2127c.png");
//   background-size: cover;
//   //display: grid;
//   grid-template-columns: 10% 40% 25% 25%;
//   //border-collapse: collapse;
// }
.sectionBox h3 {
  // background: url(../../assets/water/blue_sg.png) left 0.07rem no-repeat;
  background-size: 0.375rem 1.85rem;
  padding-left: 1.25rem;
  font-size: 1rem;
  margin-bottom: 1.25rem;

  img {
    width: 4rem;
    margin-left: 2.5rem;
    vertical-align: middle;
  }
}

.seamless-warp {
  height: 190px;
  overflow: hidden;

  .liStyle {
    height: 30px;
    border-bottom: 1px solid #1a67bd;
    font-size: .8rem;
    text-align: center;
    line-height: 30px;
    display: flex;
    margin: 0.5rem 2rem;

    //设置宽度与表头列宽一致
    .title1 {
      width: 10%;
    }

    .title2 {
      width: 25%;
    }

    .text_align {
      text-align: center;
    }
  }
}

.leftMeterTable {
  position: absolute;
  top: 8rem;
  left: 2.5rem;
  width: 24rem;
  height: 16rem;
  background-size: 100% 100%;
  background-color: rgba(6, 19, 56, 0.66);

  color: #fff;

  table {
    width: 100%;
    font-size: 0.875rem;
    padding: 0px 1.25rem;
    margin: 1rem auto 0;
    // margin: 0 auto;
  }

  table.lb_boxOne thead tr td {
    height: 2rem;
    width: 3.125rem;
    text-align: center;
  }

  table.lb_boxOne tbody tr td {
    height: 1.25rem;
    text-align: center;
  }

  .marqure1 li,
  .marqure2 li {
    line-height: 2.1875rem;
    height: 2.1875rem;
    border-bottom: 1px solid #1a67bd;
    font-size: 0.875rem;
    text-align: center;
  }

  .marqure1 li div,
  .marqure2 li div {
    width: 20%;
    float: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .marqure_box {
    position: relative;
    margin: 0px 1.375rem;
    width: 26.0625rem;
    height: 11.5625rem;
    overflow: hidden;
    margin: 0 auto;
  }

  /*左上角*/
}
</style>

