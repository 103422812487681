import * as THREE from "three";
import {curve} from "@/three/curve";
let y = 2;
let y1 = 48;

function getVector3(v3) {
    let d = [];
    let sum = 10;
    const addData = (item) => {
        if (d.length == 0)
            d.push(item);
        else {
            let lastItem = d[d.length - 1];
            let deptx = (item.x - lastItem.x) / sum;
            let depty = (item.y - lastItem.y) / sum;
            let deptz = (item.z - lastItem.z) / sum;
            for (let i = 1; i < sum; i++) {
                d.push(new THREE.Vector3(
                    lastItem.x + deptx * i, lastItem.y + depty * i, lastItem.z + deptz * i
                ));
            }
            d.push(item);
        }
    };
    v3.forEach((item) => {
        addData(item);
    })
    // let route = new THREE.CatmullRomCurve3(d, false);
    let route = new THREE.CatmullRomCurve3(v3,false)
    return route;
}
let route1 = [
    new THREE.Vector3(
        558, y, 500
    ),
    new THREE.Vector3( 540.0, y, 422.4),
    new THREE.Vector3( 527.1, y, 383.0),
    new THREE.Vector3( 512.5, y, 351.3),
    new THREE.Vector3( 510.2, y, 345.9),
    new THREE.Vector3( 514.3, y, 333.1),
    new THREE.Vector3( 515.4, y, 314.9),
    new THREE.Vector3( 499.8, y, 305.0),
    new THREE.Vector3( 487.8, y, 306.6),
    new THREE.Vector3( 476.4, y, 289.0),
    new THREE.Vector3( 456.7, y, 261.4),
    new THREE.Vector3( 422.7, y, 223.3),
    new THREE.Vector3( 415.1, y, 213.2),
    new THREE.Vector3( 416.8, y, 201.3),
    new THREE.Vector3( 411.0, y, 185.6),
    new THREE.Vector3( 396.9, y, 180.8),
    new THREE.Vector3( 383.6, y, 186.2),
    new THREE.Vector3( 375.3, y, 180.5),
    new THREE.Vector3( 300.4, y, 132.5),
    new THREE.Vector3( 272.4, y, 118.9),
    new THREE.Vector3( 205.3, y, 94.6),
    new THREE.Vector3( 128.2, y, 80.1),
    new THREE.Vector3( 42.4, y, 78.4),
    new THREE.Vector3( -0.9, y, 83.1),
    new THREE.Vector3( -32.4, y, 85.8),
    new THREE.Vector3( -40.2, y, 69.3),
    new THREE.Vector3( -56.8, y, 25.0),
    new THREE.Vector3( -72.9, y, -10.4),
    new THREE.Vector3( -85.7, y, -34.3),
    new THREE.Vector3( -120.2, y, -95.6),
    new THREE.Vector3( -149.7, y, -146.9),
    new THREE.Vector3( -176.2, y, -190.4),

];
let route2 = [
    new THREE.Vector3(
        310, y, 323
    ),
    new THREE.Vector3(
        332, y, 323
    ),

    new THREE.Vector3(
        334, y, 323
    ),
    new THREE.Vector3(
        334, y, 182
    ),
    new THREE.Vector3(
        334, y1, 182
    ),
    new THREE.Vector3(
        36, y1, 20
    ),
    new THREE.Vector3(
        36, y, 30
    ),
    new THREE.Vector3(
        36, y, 100
    ),
];
let route3 = [
    new THREE.Vector3(
        36, y, 100
    ),
    new THREE.Vector3(
        36, y, 318
    ),

    new THREE.Vector3(
        36, y, 320
    ),
    new THREE.Vector3(
        108, y, 320
    ),
    new THREE.Vector3(
        108, y, 220
    ),
];
let gdy = 1;
let route4 = [
    new THREE.Vector3(
        96, gdy, 420
    ),
    new THREE.Vector3(
        96, gdy, 405
    ),
    new THREE.Vector3(
        218, gdy, 405
    ),
    new THREE.Vector3(
        218, gdy, 360
    ),
];
let route5 = [
    new THREE.Vector3(
        340, gdy, 323
    ),
    new THREE.Vector3(
        330, gdy, 323
    ),
    new THREE.Vector3(
        330, gdy, 405
    ),
    new THREE.Vector3(
        220, gdy, 405
    ),
    new THREE.Vector3(
        220, gdy, 360
    ),
];
let route6 = [
    new THREE.Vector3(
        212, gdy, 340
    ),
    new THREE.Vector3(
        212, gdy, 324
    ),
    new THREE.Vector3(
        40, gdy, 324
    ),
    new THREE.Vector3(
        40, gdy, 20
    ),
];
export let akbutton = [
    {
        name: '仿真漫游',
        index: 1,
        position: [-33.913844660166816, 12.012800216674805, -383.4020480867691],
        controls: [108.21390562685808, -129.51278441906786, 67.9566070128961],
        cameraPosition: [433.456706289105, 219.68312417497387,540.5150079031882],
        type: "camera",
        route: getVector3(route1),
        speed: 0.0005

    },
    {
        name: '管路总览',
        index: 2,
        position: [-218.4089150128667, 177.53370260091157, -21.851148072842484],
        cameraPosition: [-523, 173, 123],
        type: "line",
        speed: 0.0005
    },
    {
        name: '数据图表',
        index: 3,
        position: [-112.91255179943249, 34.0192985534668, 71.60845959057795],
        cameraPosition: [-354, 109, 247],
        route: getVector3(route2),
        type: "line",
        speed: 0.001
    },
    {
        name: '步行模式',
        index: 4,
        position: [290.68172190553724, 6.095502289736345, -60.573421562674056],
        cameraPosition: [451, 109, -94],
        route: getVector3(route3),
        type: "line",
        speed: 0.001
    },
]

export let tube = [
    {
        name: '管道总表到水泵房1',
        modelname: 'SHDN200PE-638g',
        position: [-218.4089150128667, 177.53370260091157, -21.851148072842484],
        cameraPosition: [-523, 173, 123],
        route: getVector3(route4),
        type: "tube",
        model: "DN200",
        matera: "PE",
        speed: 0.002
    },
    {
        name: '管道总表到水泵房2',
        modelname: 'SHDN165PE-968g',
        position: [-112.91255179943249, 34.0192985534668, 71.60845959057795],
        cameraPosition: [-354, 109, 247],
        route: getVector3(route5),
        type: "tube",
        model: "DN165",
        matera: "PE",
        speed: 0.002
    },
    {
        name: '管道水泵房到绿化',
        modelname: 'SHDN315PE-2358g',
        position: [-112.91255179943249, 34.0192985534668, 71.60845959057795],
        cameraPosition: [-354, 109, 247],
        route: getVector3(route6),
        type: "tube",
        model: "DN315",
        matera: "PE",
        speed: 0.002
    },
    {
        name: '管道水泵房到绿化',
        modelname: 'SHDN110PE-206g',
        position: [-112.91255179943249, 34.0192985534668, 71.60845959057795],
        cameraPosition: [-354, 109, 247],
        route: getVector3(route6),
        type: "tube",
        model: "DN110",
        matera: "PE",
        speed: 0.002
    },
    {
        name: '管道水泵房到绿化',
        modelname: 'SHDN100PE-706g',
        position: [-112.91255179943249, 34.0192985534668, 71.60845959057795],
        cameraPosition: [-354, 109, 247],
        route: getVector3(route6),
        type: "tube",
        model: "DN100",
        matera: "PE",
        speed: 0.002
    },
    {
        name: '管道水泵房到绿化',
        modelname: 'SHDN150PE-250g',
        position: [-112.91255179943249, 34.0192985534668, 71.60845959057795],
        cameraPosition: [-354, 109, 247],
        route: getVector3(route6),
        type: "tube",
        model: "DN150",
        matera: "PVC",
        speed: 0.002
    },
]
export let meters = [
    {
        name: '德堡路市政总表',
        position: [551.0651060867685, 1.6, 423.4],
        rotateXYZ: [0, Math.PI/1.8, 0],
        cameraPosition: [-523, 173, 123],
        type: "meter",
        meterId: 1492
    }
]
export let textSp = [
    {
        name: 'A栋',
        position: [111.71235587654394, 13.911935671301023, 114.76128190961768],
        type: "Sprite",
    },
    {
        name: 'P栋',
        position: [248.29313710850641, 45.91567892535305, 124.72028873710038],
        type: "Sprite",
    },
    {
        name: 'N栋',
                position: [594.1011299819496, 43.24412541391602, 384.4030587759069],
        cameraPosition: [439.42607442490953, 280.4437072630144, 759.9867584185006],
        cameraControls: [679.3293353122097, -69.4219041360142, 293.2634241362711],
        type: "Sprite",
    },
    {
        name: 'CUB2',
        position: [112.02164845394536, 23.65347939162899, 195.30469986251188],
        type: "Sprite",
    },
    {
        name: 'CUB3',
        position: [111.89804619575085, 23.624180873129035, 272.9379017353631],
        type: "Sprite",
    },
]

let buildingIndex = 1;
export let buildingInfo = [
    {
        name: '先进材料楼',
        id: buildingIndex++,
        info: '复旦大学江湾校区先进材料楼是复旦大学江湾校区的一栋重要建筑，是学校材料科学研究和教学的重要场所。\n' +
            '该楼建筑面积约为 3.5 万平方米，共 10 层，内部设有多个材料科学实验室、研究室、教室和办公室等。这些实验室和研究室配备了先进的仪器设备和技术，为学校的科研工作提供了强有力的支持。\n' +
            '先进材料楼是一个多功能的综合性建筑，除了材料科学实验室和研究室外，还设有多个会议室、图书馆和休闲区等，为师生提供了舒适的学习和工作环境。该楼采用了现代化的建筑设计和智能化的管理系统，为师生提供了高效便捷的服务。\n' +
            '此外，先进材料楼还注重环保和可持续发展，采用了多项节能环保措施，如太阳能发电、雨水收集利用等，为学校的可持续发展做出了贡献。',
        position: [594.1011299819496, 43.24412541391602, 384.4030587759069],
        cameraPosition: [439.42607442490953, 280.4437072630144, 759.9867584185006],
        cameraControls: [679.3293353122097, -69.4219041360142, 293.2634241362711],
        type: "Sprite",
    },
    {
        name: '淞沪路门卫室',
        id: buildingIndex++,
        info: '复旦大学江湾校区的淞沪路门卫室是复旦大学江湾校区的一个重要建筑，它是学校安全管理的重要场所。\n' +
            '淞沪路门卫室建筑面积约为 30 平方米，虽然面积不大，但它是学校安全管理的重要窗口。门卫室内部设有监控设备和通讯设备等，为学校的安全管理提供了强有力的支持。\n' +
            '门卫室的工作人员负责对进出学校的人员和车辆进行管理和检查，确保学校的安全和秩序。他们还负责处理学校的突发事件和紧急情况，保障学校的安全和稳定。',
        position: [543.6311288203576, 2.9047930485225177, 482.22378468457873],
        cameraPosition: [572.3925008676877, 97.28490284627622, 570.0860899304429],
        cameraControls: [401.16851035775625, -125.23558703377236, 230.7347971162555],
        type: "Sprite",
    },
    {
        name: '环境科学楼',
        id: buildingIndex++,
        info: '复旦大学江湾校区的环境科学楼是复旦大学环境科学与工程系的主要教学和科研场所。\n' +
            '环境科学楼建筑面积约为 2.5 万平方米，共 10 层，内部设有多个环境科学实验室、研究室、教室和办公室等。这些实验室和研究室配备了先进的仪器设备和技术，为学校的科研工作提供了强有力的支持。\n' +
            '环境科学楼是一个多功能的综合性建筑，除了环境科学实验室和研究室外，还设有多个会议室、图书馆和休闲区等，为师生提供了舒适的学习和工作环境。该楼采用了现代化的建筑设计和智能化的管理系统，为师生提供了高效便捷的服务。',
        position: [499.77548602531215, 30.487998920959473, 411.1197634555006],
        cameraPosition: [441.4661690453984, 340.6261862864901, 735.043311653121],
        cameraControls: [508.3646272208245, -119.14527917762386, 308.5166568867055],
        type: "Sprite",
    },
    {
        name: '',
        id: buildingIndex++,
        info: '',
        position: [644.4990473290588, 12.55215501734131, 105.28961064447917],
        cameraPosition:[523.280200760491, 359.28396490059885, 406.3903390745479],
        cameraControls: [628.5779563636178, 73.9318034275019, 182.58526698681862],
        type: "Sprite",
    },
    {
        name: '信息科学与工程学院',
        id: buildingIndex++,
        info: '复旦大学江湾校区的信息科学与工程学院楼是复旦大学信息科学与工程学院的主要教学和科研场所。\n' +
            '信息科学与工程学院楼建筑面积约为 3.5 万平方米，共 12 层，内部设有多个信息科学实验室、研究室、教室和办公室等。这些实验室和研究室配备了先进的仪器设备和技术，为学校的科研工作提供了强有力的支持。\n' +
            '学院楼是一个多功能的综合性建筑，除了信息科学实验室和研究室外，还设有多个会议室、图书馆和休闲区等，为师生提供了舒适的学习和工作环境。该楼采用了现代化的建筑设计和智能化的管理系统，为师生提供了高效便捷的服务。',
        position:[573.1459980488698, 29.168258681433162, 162.5013330555881],
        cameraPosition: [356.8625572365352, 412.6136838041476, 489.7172471868763],
        cameraControls:[500.10690116964673, 24.427981972396765, 185.25861427032328],
        type: "Sprite",
    },
    {
        name: '交叉学科楼1号楼',
        id: buildingIndex++,
        info: '复旦大学江湾校区交叉学科楼 1 号楼是复旦大学江湾校区的一栋重要建筑，它是学校交叉学科研究和教学的重要场所。该楼建筑面积约为 3.6 万平方米，共 16 层，拥有现代化的教学、研究和办公设施。\n' +
            '交叉学科楼 1 号楼是一个多功能的综合性建筑，内部设有多个学科的实验室、研究室、教室和办公室等。这些实验室和研究室配备了先进的仪器设备和技术，为学校的科研工作提供了强有力的支持。\n' +
            '此外，交叉学科楼 1 号楼还拥有宽敞明亮的会议室、图书馆和休闲区等，为师生提供了舒适的学习和工作环境。该楼采用了现代化的建筑设计和智能化的管理系统，为师生提供了高效便捷的服务。',
        position: [409.7753850511862, 20.460869058945047, 255.21841846322377],
        cameraPosition: [310.3306039641021, 299.26708407928857, 600.7896198800606],
        cameraControls: [382.6150000989839, -73.21165117814964, 254.47083871263013],
        type: "Sprite",
    },
    {
        name: '',
        id: buildingIndex++,
        info: '',
        position: [594.1011299819496, 43.24412541391602, 384.4030587759069],
        cameraPosition: [439.42607442490953, 280.4437072630144, 759.9867584185006],
        cameraControls: [679.3293353122097, -69.4219041360142, 293.2634241362711],
        type: "Sprite",
    },
    {
        name: '',
        id: buildingIndex++,
        info: '',
        position: [594.1011299819496, 43.24412541391602, 384.4030587759069],
        cameraPosition: [439.42607442490953, 280.4437072630144, 759.9867584185006],
        cameraControls: [679.3293353122097, -69.4219041360142, 293.2634241362711],
        type: "Sprite",
    },
    {
        name: '生命科学学院',
        id: buildingIndex++,
        info: '复旦大学江湾校区的生命科学学院楼是复旦大学生命科学学院的主要教学和科研场所。\n' +
            '生命科学学院楼建筑面积约为 3.6 万平方米，共 11 层，内部设有多个生命科学实验室、研究室、教室和办公室等。这些实验室和研究室配备了先进的仪器设备和技术，为学校的科研工作提供了强有力的支持。\n' +
            '学院楼是一个多功能的综合性建筑，除了生命科学实验室和研究室外，还设有多个会议室、图书馆和休闲区等，为师生提供了舒适的学习和工作环境。该楼采用了现代化的建筑设计和智能化的管理系统，为师生提供了高效便捷的服务。',
        position: [430.77141923829384, 33.868118757770404, 21.955949260862145],
        cameraPosition: [474.35271575586404, 308.8211418008584, 448.0851400052894],
        cameraControls: [451.1308410106423, 88.72831113137471, 117.89148442940639],
        type: "Sprite",
    },
    {
        name: '技术工程学院',
        id: buildingIndex++,
        info: '复旦大学江湾校区的技术工程学院楼是复旦大学技术工程学院的主要教学和科研场所。\n' +
            '技术工程学院楼建筑面积约为 3.5 万平方米，共 12 层，内部设有多个技术工程实验室、研究室、教室和办公室等。这些实验室和研究室配备了先进的仪器设备和技术，为学校的科研工作提供了强有力的支持。\n' +
            '学院楼是一个多功能的综合性建筑，除了技术工程实验室和研究室外，还设有多个会议室、图书馆和休闲区等，为师生提供了舒适的学习和工作环境。该楼采用了现代化的建筑设计和智能化的管理系统，为师生提供了高效便捷的服务。',
        position: [366.1775956637854, 19.795012435965194, 120.45141305269821],
        cameraPosition:[447.3896295237283, 290.8024294017682, 462.7921373713623],
        cameraControls: [411.33529715851586, 70.70989608948119, 133.7521372813532],
        type: "Sprite",
    },
    {
        name: '上海数学中心',
        id: buildingIndex++,
        info: '复旦大学江湾校区的上海数学中心是一个致力于推动数学研究和教育的重要机构。该中心的建筑设计现代且富有创意，为数学家们提供了一个舒适、高效的工作环境。\n' +
            '上海数学中心的建筑面积约为 3.6 万平方米，共 6 层，内部设有多个数学实验室、研究室、教室和办公室等。这些实验室和研究室配备了先进的数学研究设备和技术，为数学家们的研究工作提供了强有力的支持。\n' +
            '中心还拥有一个宽敞的图书馆，收藏了丰富的数学书籍、期刊和论文，为数学家们提供了丰富的学术资源。',
        position: [272.16107447226307, 30.487998867473365, 229.85224331089097],
        cameraPosition: [229.10210706810363, 190.6609421208998, 491.1376176947856],
        cameraControls:[311.10497205852226, -9.738810610356133, 157.78580804022744],
        type: "Sprite",
    },
    {
        name: '',
        id: buildingIndex++,
        info: '',
        position: [594.1011299819496, 43.24412541391602, 384.4030587759069],
        cameraPosition: [439.42607442490953, 280.4437072630144, 759.9867584185006],
        cameraControls: [679.3293353122097, -69.4219041360142, 293.2634241362711],
        type: "Sprite",
    },
    {
        name: '李兆基图书馆',
        id: buildingIndex++,
        info: '复旦大学江湾校区的李兆基图书馆是由著名华人企业家李兆基先生捐赠建造而成，是一座现代化的图书馆。\n' +
            '李兆基图书馆建筑面积约为 2.1 万平方米，共 11 层，内部设有多个图书借阅区、阅览室、多媒体教室、研究室和会议室等。图书馆馆藏丰富，拥有大量的图书、期刊、报纸、论文等文献资源，为师生提供了广泛的学习和研究资料。\n' +
            '图书馆的建筑设计现代化，采用了大量的玻璃幕墙和钢结构，使整个建筑显得简洁大方、明亮通透。图书馆内部还采用了智能化的管理系统，包括自动借还书系统、电子检索系统、自助打印系统等，为师生提供了高效便捷的服务。',
        position: [154.64809599681908, 16.209424942749024, 161.06244478821455],
        cameraPosition: [-72.0043818258477, 129.41843961497543, -47.231028940275735],
        cameraControls: [118.87317519563811, -1.433792612593529, 179.19591371133416],
        type: "Sprite",
    },
    {
        name: '综合保障楼',
        id: buildingIndex++,
        info: '复旦大学江湾校区的综合保障楼是学校重要的后勤保障设施之一，为师生提供了多种便利服务。\n' +
            '综合保障楼建筑面积约为 3.6 万平方米，共 10 层，内部设有多个功能区域，包括学生食堂、超市、医务室、理发店、洗衣房、维修服务中心等。这些服务设施为师生提供了便利的生活服务，满足了他们的日常需求。\n' +
            '综合保障楼的学生食堂提供了多种美食选择，包括中餐、西餐、快餐等，为师生提供了健康、美味的饮食服务。超市则提供了丰富的商品选择，包括食品、饮料、生活用品等，方便师生购买所需物品。',
        position: [-277.20322124862156, 12.10000024536133, 345.23613459962843],
        cameraPosition: [-237.0170010484645, 178.08038016735935, 53.414792864743006],
        cameraControls:[-265.43607513673885, 89.31056988244201, 170.9389928356054],
        type: "Sprite",
    },
    {
        name: '',
        id: buildingIndex++,
        info: '',
        position: [594.1011299819496, 43.24412541391602, 384.4030587759069],
        cameraPosition: [439.42607442490953, 280.4437072630144, 759.9867584185006],
        cameraControls: [679.3293353122097, -69.4219041360142, 293.2634241362711],
        type: "Sprite",
    },
    {
        name: '教学中心专家楼',
        id: buildingIndex++,
        info: '复旦大学江湾校区的教学中心专家楼是学校重要的教学和科研设施之一，为学校的教学和科研工作提供了重要的支持。\n' +
            '教学中心专家楼建筑面积约为 3.6 万平方米，共 12 层，内部设有多个教学和科研区域，包括多媒体教室、实验室、研究室、会议室等。这些教学和科研设施配备了先进的设备和技术，为师生提供了良好的教学和科研环境。\n' +
            '教学中心专家楼还拥有一支高素质的管理团队，他们负责维护教学和科研设施的正常运行，为师生提供优质的服务。教学中心专家楼还定期举办各种学术活动和培训课程，为师生提供了更多的学习和交流机会。',
        position: [-489.1323494708265, 15.300005914985352, 290.4281704799618],
        cameraPosition: [-413.9712189143946, 183.49242668379958, 33.37081862092485],
        cameraControls:[-439.6227741679807, 103.35479049350363, 139.4183133348867],
        type: "Sprite",
    },
    {
        name: '法学院',
        id: buildingIndex++,
        info: '复旦大学江湾校区法学院楼是法学院的主要教学和办公场所，为法学院师生提供了良好的学习和工作环境。\n' +
            '法学院楼建筑面积约为 2.4 万平方米，共 6 层，内部设有多个教学和办公区域，包括教室、办公室、图书馆、会议室等。法学院楼采用了现代化的建筑设计和智能化的管理系统，为师生提供了高效便捷的服务。\n' +
            '法学院楼还拥有一支高素质的管理团队，他们负责维护教学和办公设施的正常运行，为师生提供优质的服务。法学院楼还定期举办各种学术活动和培训课程，为师生提供了更多的学习和交流机会。',
        position: [80.29948464242992, 24.100011840258787, 21.35536951462688],
        cameraPosition: [31.56201295630231, 213.34354263260275, 278.92947305245445],
        cameraControls: [82.70118546950603, 19.67468456657666, 24.56189009022759],
        type: "Sprite",
    },
    {
        name: 'A教学楼',
        id: buildingIndex++,
        info: '复旦大学江湾校区的 A 教学楼是江湾校区的主要教学楼之一，为师生提供了良好的学习和教学环境。\n' +
            'A 教学楼建筑面积约为 2.4 万平方米，共 6 层，内部设有多个教室、实验室、办公室等教学和办公区域。教学楼采用了现代化的建筑设计和智能化的管理系统，为师生提供了高效便捷的服务。\n' +
            'A 教学楼还拥有一支高素质的管理团队，他们负责维护教学设施的正常运行，为师生提供优质的服务。教学楼还定期举办各种学术活动和培训课程，为师生提供了更多的学习和交流机会。',
        position: [-26.86583144026783, 8.100004152709946, -0.7094583992081702],
        cameraPosition: [-19.12507951604208, 116.05694611923342, 321.3616305638268],
        cameraControls: [2.4294586087559438, 17.986979944720485, 13.557484339745956],
        type: "Sprite",
    },
    {
        name: '学校食堂',
        id: buildingIndex++,
        info: '复旦大学江湾校区的学校食堂是江湾校区师生就餐的主要场所，为师生提供了丰富多样的美食选择。\n' +
            '学校食堂建筑面积约为 1.2 万平方米，共 3 层，内部设有多个餐厅和小吃摊位，提供了多种不同风味的美食，包括中餐、西餐、快餐等。食堂的菜品丰富多样，既有传统的家常菜，也有各种时尚的小吃和特色菜肴，满足了师生不同的口味需求。\n' +
            '学校食堂还采用了现代化的管理模式，引进了先进的餐饮设备和管理系统，保证了食品的安全和质量。食堂还注重环保和节能，采用了环保材料和节能设备，为师生提供了一个舒适、健康的就餐环境。',
        position: [-170.7107093240131, 19.80501174396973, -25.383146077569663],
        cameraPosition:[-83.03989720308697, 103.8500199534922, -359.12065297322545],
        cameraControls:[-91.98438191426573, 57.76702117422843, -70.71547201284565],
        type: "Sprite",
    },
    {
        name: '体育馆',
        id: buildingIndex++,
        info: '复旦大学江湾校区的体育馆是一个集体育教学、训练、比赛和健身于一体的综合性体育场馆。\n' +
            '体育馆建筑面积约为 2.4 万平方米，共 4 层，内部设有多个运动场地和健身设施，包括篮球馆、羽毛球馆、乒乓球馆、健身房、瑜伽室等。体育馆的设施齐全，设备先进，能够满足师生不同的运动需求。\n' +
            '体育馆还拥有一支专业的管理团队，他们负责维护运动设施的正常运行，为师生提供优质的服务。体育馆还定期举办各种体育比赛和活动，为师生提供了更多的运动和交流机会。',
        position: [-385.1780939770816, 12.099999961568452, 44.44193324907007],
        cameraPosition: [-439.3058625504819, 161.4736765414155, 332.1753942322946],
        cameraControls: [-331.8155828426065, 26.854981514483644, 38.11074727126039],
        type: "Sprite",
    },{
        name: '化学楼',
        id: buildingIndex++,
        info: '复旦大学江湾校区的化学楼是一个现代化的化学实验和教学中心，为化学系师生提供了良好的实验和教学环境。\n' +
            '化学楼建筑面积约为 2.4 万平方米，共 5 层，内部设有多个实验室、教室、办公室等教学和办公区域。化学楼采用了现代化的建筑设计和智能化的管理系统，为师生提供了高效便捷的服务。\n' +
            '化学楼还拥有一支高素质的管理团队，他们负责维护实验设施的正常运行，为师生提供优质的服务。化学楼还定期举办各种学术活动和培训课程，为师生提供了更多的学习和交流机会。',
        position: [667.7248410497705, 38.36812050442263, 313.21502903810034],
        cameraPosition: [547.235199380893, 248.79871200492082, 645.6597454186694],
        cameraControls: [620.6071255388247, -48.181184705805855, 265.40827198041615],
        type: "Sprite",
    },{
        name: '智华楼',
        id: buildingIndex++,
        info: '复旦大学江湾校区的智华楼是一个现代化的多功能教学楼，为师生提供了良好的学习和教学环境。\n' +
            '智华楼建筑面积约为 2.4 万平方米，共 6 层，内部设有多个教室、实验室、办公室等教学和办公区域。智华楼采用了现代化的建筑设计和智能化的管理系统，为师生提供了高效便捷的服务。\n' +
            '智华楼还拥有一支高素质的管理团队，他们负责维护教学设施的正常运行，为师生提供优质的服务。智华楼还定期举办各种学术活动和培训课程，为师生提供了更多的学习和交流机会。',
        position: [-42.31594850668432, 8.10000415270996, -72.82713338535278],
        cameraPosition: [-273.64571395652695, 88.81594359037923, -68.09307101244865],
        cameraControls:[70.14171846395448, -5.113108088704056, -109.15406632147491],
        type: "Sprite",
    },
]
export let curveTest =  getVector3(route1);
export default [akbutton, tube, textSp,meters,buildingInfo,curveTest]
