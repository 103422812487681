import * as THREE from "three";
export let curve = new THREE.CatmullRomCurve3(
  [
    new THREE.Vector3(
      247.8136410285273,
      5.226696613573893,
      27.264257040936556
    ),
    new THREE.Vector3(
      172.9267671003173,
      4.922106216119488,
      26.327727484357652
    ),
    new THREE.Vector3(
      88.41242991088691,
      4.256100177764893,
      25.715480175216925
    ),
    new THREE.Vector3(
      -47.703025517324576,
      5.736959934234619,
      25.243020231588545
    ),
    new THREE.Vector3(
      -221.53915292275474,
      4.256100177764893,
      25.980170088048915
    ),
    new THREE.Vector3(
      -312.9081012460704,
      4.626928713862071,
      25.910103357675936
    ),
    new THREE.Vector3(
      -311.9914494856142,
      6.238986910862394,
      -49.63249156259799
    ),
    new THREE.Vector3(
      -312.2489741424233,
      4.256100177764921,
      -309.92463851836413
    ),
    new THREE.Vector3(
      -235.05618309986227,
      4.714111212150186,
      -311.682041340453
    ),
    new THREE.Vector3(
      -146.89815937161939,
      20.742294930662467,
      -311.6983047677284
    ),
    new THREE.Vector3(
      20.366678829227858,
      6.08636532987444,
      -310.78167600348024
    ),
    new THREE.Vector3(
      210.92743497132244,
      4.302199840545654,
      -288.5599691395312
    ),
    new THREE.Vector3(
      247.65887097794243,
      4.302199840545654,
      -222.19703393500717
    ),
    new THREE.Vector3(
      247.675102861609,
      6.27562080612347,
      -142.1290306730999
    ),
    new THREE.Vector3(
      246.56862703653854,
      7.08092538765743,
      -32.716486811368306
    ),
  ],
  true
);

export let curve1 = new THREE.CatmullRomCurve3(
  [
    new THREE.Vector3(
      85.16496461431973, 2.284435030036491, 25.493041383005107
    ),
    new THREE.Vector3(
      219.9868899554127, 1.8146109199141862e-14, 81.72281062747962
    ),
    new THREE.Vector3(
      306.0255068172082, 1.1353128870836385e-14, 51.129946952187964
    ),
    new THREE.Vector3(
      340.4538623025647, -9.713266239704356e-15, -43.74466221768296
    ),
    new THREE.Vector3(
      427.62594168146444, 3.207159695622172e-14, -111.56236789878312
    ),
    new THREE.Vector3(
      436.79971083739514, 1.3012051557743608e-14, -197.3989294532202
    ),
    new THREE.Vector3(
      364.50758858085555, -1.1434810858397636e-13, -258.978099209317
    ),
    new THREE.Vector3(
      280.80980483638587, -5.452782274054471e-14, -245.57148217564162
    ),
    new THREE.Vector3(
      246.58724261600463, -1.044225818896776e-13, -214.27750088741715
    ),
    new THREE.Vector3(
      138.8946680591077, -3.642268527730056e-14, -164.03319184268366
    ),
    new THREE.Vector3(
      121.66234934459077, -2.1987897754756652e-14, -99.02468813498263
    ),
    new THREE.Vector3(
      125.36595839566235, -6.785378224261273e-14, -49.586268423509466
    )
  ], true)

export let curve2 = new THREE.CatmullRomCurve3(
  [
    new THREE.Vector3(
      24.097547912776818, 4.132095813751221, -219.3728047122807
    ),
    new THREE.Vector3(
      20.90348056457657, -4.1066133896401034e-14, -184.9454253133786
    ),
    new THREE.Vector3(
      2.203395234289829, -3.474774907066134e-14, -156.48994976659392
    ),
    new THREE.Vector3(
      -28.793381446969494, -3.09589669479488e-14, -139.4267920105577
    ),
    new THREE.Vector3(
      -56.618671024406886, 4.979849815368652, -140.87753295898438
    ),
    new THREE.Vector3(
      -151.62305500641895, -3.2304404976792615e-14, -145.48610621590882
    )
  ])

// const c = e.from.clone().add(e.to.clone()).divideScalar(2)
//       c. y = 600
//       const curve = new THREE.QuadraticBezierCurve3(e.from,c,e.to)

let points = [
  [
    new THREE.Vector3(
      -245.9567108154297, 160.48780822753906, -49.125885009765625
    ),
    new THREE.Vector3(
      316.242097476937, 10.188995361328125, -75.1158447265625
    )
  ],
  [
    new THREE.Vector3(
      -245.9567108154297, 160.48780822753906, -49.125885009765625
    ),
    new THREE.Vector3(
      -381.98520668381593, 23.584529876708984, -45.9608154296875
    )
  ],
  [
    new THREE.Vector3(
      -245.9567108154297, 160.48780822753906, -49.125885009765625
    ),
    new THREE.Vector3(
      -4.074773594372276, 10.444631576538086, -407.1650085449219
    )
  ],
  [
    new THREE.Vector3(
      -245.9567108154297, 160.48780822753906, -49.125885009765625
    ),
    new THREE.Vector3(
      259.16119384765625, 8.500722885131836, -87.75311279296875
    )
  ],
  [
    new THREE.Vector3(
      -245.9567108154297, 160.48780822753906, -49.125885009765625
    ),
    new THREE.Vector3(
      -42.000763583009714, 1.891174097092519, 2.7403676703946003
    )
  ],
  [
    new THREE.Vector3(
      -245.9567108154297, 160.48780822753906, -49.125885009765625
    ),
    new THREE.Vector3(
      -389.73764262640844, 4.132095813751221, -240.13236516954615
    )
  ]
]

let curves3 = []

points.forEach((point, index) => {
  curves3[index] = [];
  let c = point[0].clone().add(point[1].clone()).divideScalar(2)
  c.y = 300
  let curve = new THREE.QuadraticBezierCurve3(point[0], c, point[1])
  let points = curve.getPoints(80)
  curves3[index] = new THREE.CatmullRomCurve3(points)
})
export { curves3 }

export default [curve, curve1, curve2]