//根据想要的时间类型，传入类型 获取时间
export function getDate(type, date) {
    var num = date
    let n = num;
    let d = new Date();
    let year = d.getFullYear();
    let mon = d.getMonth() + 1;
    let day = d.getDate();
    if (day <= n) {
        if (mon > 1) {
            mon = mon - 1;
        } else {
            year = year - 1;
            mon = 12;
        }
    }
    d.setDate(d.getDate() - n);
    year = d.getFullYear();
    mon = d.getMonth() + 1;
    day = d.getDate();
    let HH = d.getHours()
    let mm = d.getMinutes()
    let ss = d.getSeconds()
    if (type === 'yy-MM-dd HH:mm:ss') {
        return year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day) + " " + (HH < 10 ? ('0' + HH) : HH) + ":" + (mm < 10 ? ('0' + mm) : mm) + ":" + (ss < 10 ? ('0' + ss) : ss)
    } else if (type === 'yy-MM-dd HH') {
        return year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day) + " " + (HH < 10 ? ('0' + HH) : HH)
    } else if (type === 'yy-MM-dd HH:mm') {
        return year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day) + " " + (HH < 10 ? ('0' + HH) : HH) + ":" + (mm < 10 ? ('0' + mm) : mm)
    } else if (type === 'yy-MM-dd') {
        return year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day)
    } else if (type === 'yy-MM') {
        return year + "-" + (mon < 10 ? ('0' + mon) : mon)
    } else if (type === 'yy') {
        return year
    }
}
//时间戳根据状态格式化 返回时间
export function format(date, type) {
    let yy = new Date(date).getFullYear()
    let MM = addZero(new Date(date).getMonth() + 1)
    let dd = addZero(new Date(date).getDate())
    let HH = addZero(new Date(date).getHours())
    let mm = addZero(new Date(date).getMinutes())
    let ss = addZero(new Date(date).getSeconds())
    if (type === 'yy') {
        return yy.toString()
    } else if (type === 'yy-MM-dd HH:mm:ss') {
        return yy + '-' + MM + '-' + dd + ' ' + HH + ':' + mm + ':' + ss
    } else if (type === 'yy-MM-dd HH:mm') {
        return yy + '-' + MM + '-' + dd + ' ' + HH + ':' + mm
    } else if (type === 'MM-dd HH:mm') {
        return MM + '-' + dd + ' ' + HH + ':' + mm
    } else if (type === 'yy-MM-dd HH') {
        return yy + '-' + MM + '-' + dd + ' ' + HH
    } else if (type === 'yy-MM-dd') {
        return yy + '-' + MM + '-' + dd
    } else if (type === 'MM-dd HH') {
        return MM + '-' + dd + ' ' + HH
    } else if (type === 'yy-MM') {
        return yy + '-' + MM + '-' + dd
    } else if (type === 'MM-dd') {
        return MM + '-' + dd
    }
}
//时间小于10补0
export function addZero(data) {
    if (data < 10) {
        return '0' + data
    } else {
        return data
    }
}
//输出天数 传入两个时间 看相差了几天 。
export function DateDiff(sDate1,  sDate2){
  //sDate1和sDate2是2006-12-18格式
  var  aDate1, aDate2,  oDate1,  oDate2,  iDays;
  aDate1  =  sDate1.split("-");
  oDate1  =  new  Date(aDate1[1]  +  '/'  +  aDate1[2]  +  '/'  +  aDate1[0]);
  aDate2  =  sDate2.split("-");
  oDate2  =  new  Date(aDate2[1]  +  '/'  +  aDate2[2]  +  '/'  +  aDate2[0]);
  iDays  =  parseInt(Math.abs(oDate1  -  oDate2) / 1000 / 60 / 60 / 24 );
  return  iDays
}
//格式化时间戳到天
export function GMTToStrDay(time) {
    let date = new Date(time)
    let str =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getDate()
    return str
  }