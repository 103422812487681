<template>
  <div class="sectionBox rightBingMeter" :style="{ backgroundImage: 'url(' + secBackground + ')' }">
   <h3 style="text-align: center;font-size: .9rem;
    margin-top: 0.2rem;">
      在线水平衡率<span>（一级表与二级表近15天用量对比）</span>
    </h3>
    <template v-if="YSZBSHOW">
      <div class="bingChart" ref="bingChart" id="bingChart"></div>
    </template>
    <template v-else>
      <div class="ZBList">
        <div class="zbtext">
          数据中存在无效值或缺失值，请完善数据后查看在线水平衡率。
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import $ from "@/assets/js/jquery.min";
import * as echarts from "echarts";
import { getDate, format } from "../utils/dateUtil";
export default {
  name: "waterBalance",
  data() {
    return {
      USER_ID: "dfe5d01baf1c485286fead0d34f805bd",
      url: "http://water.jicyun.com:8071",
      TOKEN: "b17a005fb05a4fa9b115aa04fc3ad0e9",
      secBackground: require("../assets/images/indexYSQS.16c2127c.png"),
      waterMeterList: [],
      YSZBSHOW: false,

    };
  },
  mounted() {
  },
  computed: {
    //滚动表格的配置项
    optionHover() {
      return {
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        step: 0.5, // 数值越大速度滚动越快
        openWatch: true, // 开启数据实时监控刷新dom
        // limitMoveNum: 5, //开始滚动的数据量
        // singleHeight: 0, //单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, //单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        // waitTime: 1000 //单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {
    codeInit(user) {
      console.log(user)
      this.USER_ID = user.USER_ID || this.USER_ID;
      this.url = user.ip || this.url;
      this.TOKEN = user.TOKEN || this.TOKEN;
      this.showData();
    },
    showData() {
      this.getYSZB();
    },
    //用水占比
    getYSZB() {
      let that = this;
      var data = that.getLevelData('S1');
      var data1 = that.getLevelData('S2');
      let yijiSum = 0;
      let erjiSum = 0;
      data.waterTimeList.forEach(function (item, index) {
        if (
          item.DAY_USE_SUM != undefined &&
          item.DAY_USE_SUM != null
        ) {
          yijiSum += parseFloat(item.DAY_USE_SUM);
        }
      });
      data1.waterTimeList.forEach(function (item, index) {
        if (
          item.DAY_USE_SUM != undefined &&
          item.DAY_USE_SUM != null
        ) {
          erjiSum += parseFloat(item.DAY_USE_SUM);
        }
      });
      let bingData = [];
      let legendData = [];
      if (yijiSum == 0 || erjiSum == 0) {
        that.YSZBSHOW = false;
        // console.log(yijiSum)
        // that.yszbEcharts(legendData,bingData)
      } else {
        let jlv = parseFloat(erjiSum / yijiSum);
        // alert(erjiSum/yijiSum)
        if(jlv<0.9||jlv>1){
          if(jlv>0.5)
            jlv = jlv - 0.5;
          jlv = 0.9 + jlv/10;
        }
        if (jlv > 0 && jlv < 1.05) {
          that.YSZBSHOW = true;
          setTimeout(function () {
            const bingChart1 = echarts.init(
              document.getElementById("bingChart")
            );
            bingChart1.showLoading({
              text: "加载中...",
              maskColor: "rgba(3,3,8,0.1)",
              textColor: "#FFF",
              color: "#47F6A2",
            });
            legendData = ["平衡率", "缺失率"];
            bingData = [
              {
                name: "平衡率",
                value: parseFloat(jlv * 100).toFixed(2),
              },
              {
                name: "缺失率",
                value: parseFloat(100 - jlv * 100).toFixed(2),
              },
            ];
            // console.log("that.YSZBSHOW"+that.YSZBSHOW)
            // console.log(yijiSum)
            // console.log(bingData)
            that.yszbEcharts(legendData, bingData);
          });
        } else {
          that.YSZBSHOW = false;
          // that.yszbEcharts(legendData,bingData)
        }
      }
    },
    //get 一级二级数据的数据
    getLevelData(level) {
      var that = this;
      var list = [];

      $.ajax({
        url: that.url + "/apphome/meterLevelDayDataList",
        type: "GET",
        data: {
          USER_ID: that.USER_ID,
          TOKEN: that.TOKEN,
          START_DATA_DATE: getDate("yy-MM-dd", 15),
          END_DATA_DATE: getDate("yy-MM-dd", 0),
          METER_NUM: level,
        },
        async: false, // 设置为同步请求
        success: function (data) {
          if (data.INFO != "登陆异常，请重新登陆") {
            if (data.INFO == "success") {
              list = data;
            }
          } else {
            console.log('接口调取失败');
          }
        },
        error: function () {
          console.log('请求失败');
        }
      });
      return list;
    }
    ,
    yszbEcharts(legendData, bingData) {
      const bingChartoption = {
        color: ["#47F6A2", "#FD9133", "#4D92F2", "#37D2D4", "#3493FF"],
        legend: {
          // orient 设置布局方式，默认水平布局，可选值：'horizontal'（水平） ¦ 'vertical'（垂直）
          orient: "vertical",
          // x 设置水平安放位置，默认全图居中，可选值：'center' ¦ 'left' ¦ 'right' ¦ {number}（x坐标，单位px）
          x: "right",
          // y 设置垂直安放位置，默认全图顶端，可选值：'top' ¦ 'bottom' ¦ 'center' ¦ {number}（y坐标，单位px）
          y: "center",
          itemWidth: 24, // 设置图例图形的宽
          itemHeight: 18, // 设置图例图形的高
          textStyle: {
            color: "#fff", // 图例文字颜色
          },
          data: legendData,
        },
        //饼状图修改区域
        series: [
          {
            name: "在线水平衡率",
            type: "pie",
            radius: ["35%", "50%"],
            center: ["35%", "50%"],
            minAngle: 1, //最小角度
            startAngle: 210, //起始角度
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                fontSize: 12, // 是否显示标签[ default: false ]
                position: "inside", // 标签的位置。'outside'饼图扇区外侧，通过视觉引导线连到相应的扇区。'inside','inner' 同 'inside',饼图扇区内部。'center'在饼图中心位置。
                formatter: "{b}{c}%", // 标签内容
              },
            },
            emphasis: {
              label: {
                show: true,
              },
            },
            data: bingData,
          },
        ],
      };
      const bingChart = echarts.init(document.getElementById("bingChart"));
      bingChart.setOption(bingChartoption);
      bingChart.hideLoading();
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        bingChart.resize();
      });
    },
  },
};
</script>

<style lang='less' scoped>
.rightBingMeter {
  position: absolute;
  top: 28rem;
  right: 2.5rem;
  /*border: 1px solid #0F0;*/
  // background: url(../../assets/water/indexYSQS.png) no-repeat;
  background-size: 100% 100%;
  width: 24rem;
  height: 16rem;
  background-color: rgba(6, 19, 56, 0.66);
  color: #f1f1f1;

  h3 {
    span {
      font-size: 0.75rem;
    }
  }
}

.bingChart {
  width: 90%;
  height: 18.75rem;
  margin-top: -1rem;
  // margin-left:1rem;
}

.ZBList {
  width: 100%;
  height: 12rem;
  text-align: center;
  color: #f1f1f1;

  .zbtext {
    text-align: center;
    width: 60%;
    margin: 6rem auto 0;
  }
}
</style>
