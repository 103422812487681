import * as THREE from 'three';
import $ from "@/assets/js/jquery.min";
/**
 * 水表数据控件
 */
function meterData(serverInfo) {

    // 定义一个Map对象
    this.map = new Map();
// 将口径和编码放入map中
    this.map.set('074cc5dd6e294b95aaf42f228a0e08af', 'DN15');
    this.map.set('0ea3bb9797904d1ca3327f785885fc5e', 'DN20');
    this.map.set('153fe8c0f626449c9acbcea5f1ab3aaf', 'DN250');
    this.map.set('2126afa22de5441daacc936d1a72e68c', 'DN100');
    this.map.set('36fb1c0519274ba68bc481e7786d7cbb', 'DN200');
    this.map.set('43bfe0c788ff4b00b7f0aace374a2533', 'DN65');
    this.map.set('7f928e09660e41cca84cb1dc3b05cb69', 'DN300');
    this.map.set('b04de9713b664ac8830e42bbae2f8f2f', 'DN25');
    this.map.set('f0ac04444f2748599cd589e282649008', 'DN150');
    this.map.set('f500036232b343bca68d0476619c965f', 'DN125');
    this.map.set('f64c95e5aa894a1aa971662617fdbc21', 'DN40');
    this.map.set('f7405c326af4431593e16c4e6a521bdb', 'DN80');
    this.map.set('f8488ad748d64452ba8fae897bab09f0', 'DN50');
    this.map.set('hdfefetrewr123123fdfsferf', 'DN32');
    this.map.set('wu', '123');
    this.serverInfo = serverInfo;
    this.meshs = [];
    this.meterId = [];
    this.imgs = [];
    this.Sprites = [];
    this.count = 50
    this.gColor = '#28f260'
    this.prop1 = {
        width: 382,
        height: 300,
        pos: [0, 0, 0],
        scale: [0.020625 * this.count, 0.011484375 * this.count, 0],
    }
    this.tab1 = [
        ['', ''],
        ['水表口径：', ''],
        ['安装位置：', '' ],
        ['水表读数：', '', this.gColor],
        ['经度：', ''],
        ['纬度：', ''],
    ]
    this.props = [this.prop1]
    this.tabs = [this.tab1]
    this.handleDatachange();
    this.getData();
    // setInterval(() =>{this.handleDatachange();}, 10000)//周期刷新数据
}

Object.assign(meterData.prototype, {
    constructor: meterData,
    handleDatachange: function() {
        if(this.meterId.length==0){
            this.clearSprite();
            return;
        }
        this.getData();
    },
    getData:function (){
        let _that = this;
        var WATER_IDS = this.meterId.toString();
        // var value = {
        //     METER_NAME:"市政总表",
        //     METER_MODEL:"36fb1c0519274ba68bc481e7786d7cbb",
        //     VALUE:"0.00",
        //     INSTALL_ADDR:"赤峰路65号",
        //     WATER_METER_ID:'12'
        //
        // };
        // _that.showData(value);
        $.get(
            this.serverInfo.ip + "/apphome/waterTime",
            {
                USER_ID: this.serverInfo.USER_ID,
                TOKEN: this.serverInfo.TOKEN,
                WATER_ID: WATER_IDS,
                showCount: 999,
                currentPage: 1,
            },
            function (data) {
                _that.clearSprite();
                if (data.INFO == "success") {
                    $.each(data.waterTimeList, function (index, value) {
                        _that.showData(value);
                    });
                    if(_that.imgs.length==0){
                        $.each(data.waterTimeList, function (index, value) {
                            let imgItem = {};
                            imgItem.WATER_METER_ID = value.WATER_METER_ID;
                            imgItem.idx = 0;
                            imgItem.imgs = [];
                            if(value.WATER_IMG){
                                $.each(value.WATER_IMG.split(","), function (i, v) {
                                    imgItem.imgs.push(v.replace('https://water.jicyun.com:8075/','http://water.jicyun.com:8071/'));
                                });
                            }
                            _that.imgs.push(imgItem)
                        });
                    }
                } else {
                    var value = {
                        METER_NAME:"市政总表",
                        METER_MODEL:"36fb1c0519274ba68bc481e7786d7cbb",
                        VALUE:"0.00",
                        INSTALL_ADDR:"赤峰路65号",
                        WATER_METER_ID:'0'

                    };
                    _that.showData(value);
                    console.log("token失效");
                }
            }
        );
    },
    getImg:function (WATER_METER_ID){
        let imgPath = '';
        $.each(this.imgs, function (index, value) {
            if(value.WATER_METER_ID == WATER_METER_ID){
                imgPath = value.imgs[value.idx];
                value.idx = (value.idx + 1) % value.imgs.length;
            }
        });
        return imgPath;
    },
    showData:function (meter){
        console.log("showData")
        console.log(meter)
        this.tab1[0][0] = meter.METER_LEVEL + " " +meter.METER_NAME;
        this.tab1[1][1] = this.map.get(meter.METER_MODEL);
        meter.INSTALL_ADDR  = meter.INSTALL_ADDR==undefined||meter.INSTALL_ADDR==null||meter.INSTALL_ADDR==""?"--":meter.INSTALL_ADDR
        this.tab1[2][1] = meter.INSTALL_ADDR;
        meter.VALUE = meter.VALUE==undefined||meter.VALUE==null||meter.VALUE==""?"--":meter.VALUE
        this.tab1[3][1] = meter.VALUE +"m³";
        meter.LNG = meter.LNG==undefined||meter.LNG==null||meter.LNG==""?"--":meter.LNG
        meter.LAT = meter.LAT==undefined||meter.LAT==null||meter.LAT==""?"--":meter.LAT
        this.tab1[4][1] = meter.LNG||"";
        this.tab1[5][1] = meter.LAT||"";
        this.initSprite(meter.WATER_METER_ID||"0");
    },
    initSprite: function(WATER_METER_ID) {
        // console.log("initSprite")
        // console.log(this.meshs)
        let index = -1;
        this.meterId.forEach((item,idx) => {
            if(item==WATER_METER_ID){
                index = idx;
            }
        });
        if(WATER_METER_ID=='0')
            index = 0;
        if(index<0&&WATER_METER_ID!='0'){
            console.log(WATER_METER_ID)
            return;
        }
        console.log(this.meshs)
        const borderColor = 'rgba(39, 179, 236, 1)'
        const color = 'rgba(0,0,0, 1)'
        this.makeTextSprite1(this.meshs[index],this.tabs, this.props, {
            color: color,
            borderColor,
            backgroundColor: 'rgba(39, 179, 236,0.3)'
        });

    },
    // 清空雪碧图
    clearSprite: function() {
        for(let i=0; i<this.Sprites.length; i++) {
            let parent = this.Sprites[i].parent;
            parent.remove(this.Sprites[i]);
            this.Sprites[i].geometry.dispose();
            this.Sprites[i].material.dispose();
        }
        this.Sprites = [];
    },
    deleteData:function (){
        this.clearSprite();
        this.meshs = [];
        this.meterId = [];
    },
    // 添加水表
    addMeter: function(mesh,waterId) {
        this.meshs.push(mesh);
        this.meterId.push(waterId);
    },
    changeMeter: function(mesh,waterId) {
        var isHave = false;
        this.meterId.forEach((item, idx) => {
            if(item==waterId)
                isHave = true;
        });
        if(isHave){
            this.removeMeter(waterId);
        }else {
            this.addMeter(mesh,waterId);
        }
        console.log()
        this.handleDatachange();
    },
    // 删除水表
    removeMeter: function(waterId) {
        const meshschildren = [];
        const idschildren = [];
        var _that = this;
        this.meterId.forEach((item, idx) => {
            if(item!==waterId){
                meshschildren.push(_that.meshs[idx]);
                idschildren.push(_that.meterId[idx]);
            }
        });
        this.meshs = meshschildren;
        this.meterId = idschildren;

    },
        /* 创建字体精灵 */
    makeTextSprite1: function(mesh,tabs, props, parameters) {
        if (parameters === undefined) parameters = {}
        // const _that = this;
        tabs.forEach((tab, k) => {
            let {
                width,
                height
            } = props[k]
            /* 创建画布 */
            let canvas = document.createElement('canvas');
            let context = canvas.getContext('2d')
            canvas.width = width
            canvas.height = height
            let gap = 10
            let fontface = parameters.hasOwnProperty("fontface") ? parameters["fontface"] : "sans-serif"
            /* 字体大小 */
            let fontsize = parameters.hasOwnProperty("fontsize") ?
                parameters["fontsize"] : 30

            let color = parameters.hasOwnProperty("color") ? parameters["color"] : 'rgba(0, 0, 0, 1.0)'
            /* 边框厚度 */
            let borderWith = parameters.hasOwnProperty("borderWith") ? parameters["borderWith"] : 2
            /* 边框颜色 */
            // eslint-disable-next-line no-prototype-builtins
            let borderColor = parameters.hasOwnProperty("borderColor") ? parameters["borderColor"] : {
                r: 0,
                g: 0,
                b: 0,
                a: 1.0
            }
            /* 背景颜色 */
            let backgroundColor = parameters.hasOwnProperty("backgroundColor") ? parameters["backgroundColor"] : {
                r: 255,
                g: 255,
                b: 255,
                a: 1.0
            }
            /* 字体加粗 */
            // context.font = "Bold " + fontsize + "px " + fontface
            context.font = fontsize + "px " + fontface
            let unit = gap + fontsize
            /* 背景颜色 */
            context.fillStyle = backgroundColor
            /* 边框的颜色 */
            context.strokeStyle = borderColor
            context.lineWidth = borderWith
            /* 绘制圆角矩形 */
            this.roundRect(context, gap, gap, width - gap, height - gap, 4)

            tab.forEach((d, i) => {
                context.fillStyle = color;
                context.fillText(d[0], gap * 2, gap + unit * (i + 1))
                if (d[2]) {
                    context.fillStyle = d[2]
                }
                context.fillText(d[1], gap * 2 + this.measureText(d[0], context), gap + unit * (i + 1))
            })

            /* 画布内容用于纹理贴图 */
            let texture = new THREE.Texture(canvas);
            texture.needsUpdate = true
            let spriteMaterial = new THREE.SpriteMaterial({
                map: texture,
                // sizeAttenuation:false,
                // transparent:true
            });
            let sprite = new THREE.Sprite(spriteMaterial)
            // console.log(sprite.spriteMaterial)
            /* 缩放比例 */
            sprite.scale.set(...props[k].scale)
            sprite.center = new THREE.Vector2(0, 0);
            sprite.jc_type = 'meterData'
            mesh.add(sprite);
            this.Sprites.push(sprite);
            sprite.position.set(...props[k].pos);
        })
    },

    measureText: function(text, ctx, font) {
        if (font) ctx.font = font
        return ctx.measureText(text).width;
    },
    /* 绘制圆角矩形 */
    roundRect: function(ctx, x, y, w, h, r) {

        ctx.beginPath();
        ctx.moveTo(x + r, y);
        ctx.lineTo(x + w - r, y);
        ctx.quadraticCurveTo(x + w, y, x + w, y + r);
        ctx.lineTo(x + w, y + h - r);
        ctx.quadraticCurveTo(x + w, y + h, x + w - r, y + h);
        ctx.lineTo(x + r, y + h);
        ctx.quadraticCurveTo(x, y + h, x, y + h - r);
        ctx.lineTo(x, y + r);
        ctx.quadraticCurveTo(x, y, x + r, y);
        ctx.closePath();

        // ctx.shadowColor = "#qb95cf";
        // ctx.shadowOffsetX = 0;
        // ctx.shadowOffsetY = 0;
        // ctx.shadowBlur = 4;

        ctx.fill();
        ctx.stroke();
        ctx.shadowColor = "";
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 0;
    },


    makeTextSprite: function(container, scene) {
        // console.log(container.scrollWidth, container.scrollHeight)
        return window.html2canvas(container, {
            //allowTaint:true,
            scale: 1,
            width: container.scrollWidth,
            height: container.scrollHeight,
            // useCORS: true
        })
            .then(function(canvas) {
                if (!canvas) return;
                // console.log(canvas)
                /* 画布内容用于纹理贴图 */
                let texture = new THREE.Texture(canvas);
                texture.needsUpdate = true
                let spriteMaterial = new THREE.SpriteMaterial({
                    map: texture,
                    // transparent:true
                });
                let sprite = new THREE.Sprite(spriteMaterial)
                // console.log(sprite.spriteMaterial)
                /* 缩放比例 */
                sprite.scale.set(30, 20, 0)
                sprite.position.set(0, 0, 10)
                sprite.center = new THREE.Vector2(0, 0);
                scene.add(sprite);
                this.Sprites.push(sprite);
                return sprite
            });
    }

});

export default meterData
