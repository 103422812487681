<template>
  <div class="right animated slideInRight">
    <div class="title1">
      <font face="楷体">{{ page }}</font>
    </div>
    <div class="box">
      <div class="data">
        <div class="bg">
          <img :src="img_src" />
          <div class="title">{{ title }}</div>
        </div>
        <div class="title">
          <p
            class="paragraph"
            v-for="(paragraph, index) in content"
            :key="index"
          >
            {{ paragraph }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Right",
  // components: { $ },
  data() {
    return {
      img_src: require("./../assets/images/right.png"),
      title: "",
      page: "喷灌滴灌",
      content: [""],
    };
  },
  created() {},
  mounted() {
    // this.init(this.url, this.TOKEN, this.USER_ID);
  },
  methods: {
    showPD(title, content, page) {
      this.title = title || this.getPDText()[0];
      this.content = content || this.getPDText()[1];
      this.page = page || this.getPDText()[2];
    },
    getPDText() {
      let text = [
        "喷灌滴灌介绍",
        [
          "高效喷灌是借助水泵和管道系统或利用自然水源的落差，通过压力，把水喷到空中，散成弥雾降落到植物上和地面上的灌溉方式。灌溉直径可达 1.6 ～3.2m，灌溉效率在 50 ～ 60%。具有省水省力、不破坏土壤结构、调节地面气候且不受地形限制等优点。",
          "高效滴灌是按照植物需水要求，利用塑料管道将水通过直径约10㎜毛管上的孔口或滴头送到作物根部进行局部灌溉的灌水方法,是目前最有效的一种节水灌溉方式，水的利用率可达 95%。",
        ],
        "喷灌滴灌",
      ];
      return text;
    },
  },
};
</script>
<style lang='less' scoped>
.right {
  color: #fff;
  //right: -2%;
  //width: 40%;
  width: 100%;
  height: 90%;
  .data {
    padding: 2rem 1.5rem;
    .bg {
      display: flex;
      flex-direction: row;
      img {
        height: 40px;
        width: 40px;
        vertical-align: middle;
      }
    }
    .title {
      color: #fff;
      font-size: 16px;
      height: 35px;
      line-height: 35px;
      padding: 0 0.5rem;
    }
  }
}
.paragraph {
  text-indent: 2em;
}
.box {
  background-image: url("./../assets/images/box.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 85%;
  width: 100%;
}
.title1 {
  width: 95%;
  font-size: 20px;
  font-weight: 600;
  height: 50px;
  line-height: 50px;
  background-image: url("./../assets/images/title.png");
  background-size: 100% 100%;
  padding-left: 5%;
}
</style>
