<template>
  <div class="sectionBox leftYslMeter" :style="{ backgroundImage: 'url(' + secBackground + ')' }">
    <h3 style="text-align: center;font-size: .9rem;
    margin-top: 0.2rem;">用水趋势</h3>
    <div class="YslStyle" style=" width: 100%;
        height: 80%;margin-top: 1rem;" ref="YslChart" id="YslChartleft"></div>
  </div>
</template>

<script>
import $ from "@/assets/js/jquery.min";
import * as echarts from "echarts";
import { getDate, format } from "../utils/dateUtil";
export default {
  name: "useTrends",
  data() {
    return {
      USER_ID: "dfe5d01baf1c485286fead0d34f805bd",
      url: "http://water.jicyun.com:8071",
      TOKEN: "b17a005fb05a4fa9b115aa04fc3ad0e9",
      secBackground: require("../assets/images/indexYSQS.16c2127c.png"),
      waterMeterList: [],
      sbrylText: "单位用水量",
    };
  },
  mounted() {
  },
  computed: {
    //滚动表格的配置项
    optionHover() {
      return {
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        step: 0.5, // 数值越大速度滚动越快
        openWatch: true, // 开启数据实时监控刷新dom
        // limitMoveNum: 5, //开始滚动的数据量
        // singleHeight: 0, //单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, //单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        // waitTime: 1000 //单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {
    codeInit(user) {
      console.log(user)
      this.USER_ID = user.USER_ID || this.USER_ID;
      this.url = user.ip || this.url;
      this.TOKEN = user.TOKEN || this.TOKEN;
      this.showData();
    },
    showData() {
      this.getdata();
    },
    // 方法一
    // 加载显示设备状态、用水曲线
    getdata() {
      var that = this;
      $.get(
        that.url + "/appWaterMeter/meterList",
        {
          USER_ID: that.USER_ID,
          TOKEN: that.TOKEN,
        },
        function (data) {
          if (data.INFO == "success") {
            that.waterMeterList = data.meterList;
            that.getYslChart();
          } else {
            console.log("token失效");
          }
        }
      );
    },
    getYslChart() {
      let that = this;
      const YslChart = echarts.init(document.getElementById("YslChartleft"));
      YslChart.showLoading({
        text: "加载中...",
        maskColor: "rgba(3,3,8,0.1)",
        textColor: "#FFF",
        color: "#2AD1E2",
      });
      that.getMeterLevelDayDataList()
    },
    getMeterLevelDayDataList() {
      var that = this;
      let ALL_DATA_DATE1 = getDate("yy-MM-dd", 15);
      let ALL_DATA_DATE2 = getDate("yy-MM-dd", -1);
      $.get(
        that.url + "/apphome//meterLevelDayDataList",
        {
          USER_ID: that.USER_ID,
          TOKEN: that.TOKEN,
          START_DATA_DATE: ALL_DATA_DATE1,
          END_DATA_DATE: ALL_DATA_DATE2
        },
        function (data) {
          if (data.INFO != "登陆异常，请重新登陆") {
            if (data.INFO == "success") {
              let xdata = [];
              let ydata = [];
              data.waterTimeList.forEach(function (item, index) {
                xdata.push(item.DAY_USE_SUM);
                ydata.push(format(item.DATA_DATE, "yy-MM-dd"));
              });
              const YslChartoption = {
                tooltip: {
                  trigger: "axis",
                  show: true,
                  axisPointer: {
                    type: "shadow",
                  },
                },
                grid: {
                  left: "16%",
                  right: "10%",
                  bottom: "15%",
                  top: "20%",
                },
                legend: {
                  data: [
                    {
                      name: that.sbrylText,
                      textStyle: {
                        color: "#ffffff",
                      },
                    },
                  ],
                },
                xAxis: [
                  {
                    type: "category",
                    boundaryGap: true,
                    axisLine: {
                      lineStyle: {
                        color: "#ffffff",
                      },
                    },
                    axisLabel: {
                      margin: 10,
                      textStyle: {
                        color: "#ffffff",
                      },
                    },
                    axisTick: {
                      show: false,
                    },
                    data: ydata,
                  },
                ],
                yAxis: [
                  {
                    type: "value",
                    name: "用水量 （m³）",
                    axisTick: {
                      show: false,
                    },
                    axisLine: {
                      lineStyle: {
                        color: "#ffffff",
                      },
                    },
                    axisLabel: {
                      margin: 10,
                      textStyle: {
                        color: "#ffffff",
                      },
                    },
                    splitNumber: 8,
                    splitLine: {
                      show: false,
                      lineStyle: {
                        color: "#57617B",
                      },
                    },
                  },
                ],
                series: [
                  {
                    name: that.sbrylText,
                    type: "bar",
                    barWidth: 12,
                    itemStyle: {
                      normal: {
                        barBorderRadius: [10, 10, 0, 0],
                        color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                          {
                            offset: 0,
                            color: "#009AB0",
                          },
                          {
                            offset: 1,
                            color: "#31EDFE",
                          },
                        ]),
                      },
                    },
                    markPoint: {
                      data: [
                        {
                          type: "max",
                          name: "最大值",
                        },
                      ],
                      animationDelay: 1000,
                      animationDuration: 1000,
                    },
                    data: xdata,
                  },
                ],
              };
              const Chart = echarts.init(
                document.getElementById("YslChartleft")
              );
              Chart.clear();
              Chart.setOption(YslChartoption);
              Chart.hideLoading();
              window.addEventListener("resize", () => {
                Chart.resize();
              });
            }
          } else {
            // that.$store.dispatch("user/loginOut");
          }
        }
      );

    }
  },
};
</script>

<style lang='less' scoped>
// table {
//   width: 100%;
//   height: 100%;
//   // background-image: url("../assets/images/indexYSQS.16c2127c.png");
//   background-size: cover;
//   //display: grid;
//   grid-template-columns: 10% 40% 25% 25%;
//   //border-collapse: collapse;
// }
.sectionBox h1 {
  // background: url(../../assets/water/blue_sg.png) left 0.07rem no-repeat;
  background-size: 0.375rem 1.85rem;
  padding-left: 1.25rem;
  font-size: 1.25rem;
  margin-bottom: 1.25rem;

  img {
    width: 4rem;
    margin-left: 2.5rem;
    vertical-align: middle;
  }
}

.seamless-warp {
  height: 200px;
  overflow: hidden;

  .liStyle {
    height: 30px;
    border-bottom: 1px solid #1a67bd;
    font-size: .875rem;
    text-align: center;
    line-height: 30px;
    display: flex;
    margin: 0.5rem 2rem;

    //设置宽度与表头列宽一致
    .title1 {
      width: 10%;
    }

    .title2 {
      width: 25%;
    }

    .text_align {
      text-align: center;
    }
  }
}

/* 左下角  柱状图  */
.leftYslMeter {
  position: absolute;
  top: 28rem;
  left: 2.5rem;
  width: 24rem;
  height: 16rem;
  color: #fff;
  background-size: 100% 100%;
  background-color: rgba(6, 19, 56, 0.66);
   span {
    float: right;
    margin-right: 3rem;
    font-size: 0.9rem;
    color: #23a2f7;
  }
}

.BJList {
  width: 100%;
  height: 9rem;
  line-height: 9rem;
  text-align: center;
  color: #f1f1f1;
}

.ZBList {
  width: 100%;
  height: 12rem;
  text-align: center;
  color: #f1f1f1;

  .zbtext {
    text-align: center;
    width: 60%;
    margin: 6rem auto 0;
  }

  .YslStyle {
    width: 10rem;
    height: 15rem;
    margin-top: 1rem;
  }
}
</style>
