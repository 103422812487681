<template>
  <div class="main">
    <div id="screen" class="screen"></div>
    <transition mode="out-in" name="leftSlide">
      <div class="windowLeft"  v-show="leftShow">
        <big-left ref="Left"></big-left>
      </div>
    </transition>
    <transition mode="out-in" name="rightSlide">
      <div class="windowRight"  v-show="rightShow">
        <bigRight ref="Right"></bigRight>
      </div>
    </transition>

    <transition mode="out-in" name="alarmRecord">
      <div v-show="dataShow">
        <alarmRecord
            ref="alarmRecord"
            @lookMeter="lookMeter"
        ></alarmRecord>
      </div>
    </transition>
    <transition mode="out-in" name="meterTimeData">
      <div v-show="dataShow">
        <meterTimeData
            ref="meterTimeData"
            @lookMeter="lookMeter"
        ></meterTimeData>
      </div>
    </transition>
    <transition mode="out-in" name="useTrends">
      <div v-show="dataShow">
        <useTrends
            ref="useTrends"
        ></useTrends>
      </div>
    </transition>
    <transition mode="out-in" name="waterBalance">
      <div v-show="dataShow">
        <waterBalance
            ref="waterBalance"
        ></waterBalance>
      </div>
    </transition>


    <div class="downBottom">
      <div
          class="btn"
          v-for="(item, key) in akbutton"
          :key="key"
          @click="buttonClick(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div>
      <canvas ref="canvas"
              @keydown="onKeyDown"
              @keyup="onKeyUp"
              tabindex="0"></canvas>
    </div>
  </div>
</template>

<script>
import ZThree from "@/three/ZThree";
import { akbutton, tube,meters,buildingInfo,curveTest } from "@/assets/mock/jwxq.js";
import bigLeft from "@/components/code";
import bigRight from "@/components/textcode";
import * as THREE from "three";
import GLTFUtil from "@/utils/GLTFUtil";
import waterPipe from "@/assets/mock/waterPipe";
import BaseNet from "@/three/baseNet";
import meterData from "@/assets/mock/meterData";
import {createSprite} from "@/three/sprite";
import alarmRecord from "@/components/alarmRecord";
import meterTimeData from "@/components/meterTimeData";
import useTrends from "@/components/useTrends";
import waterBalance from "@/components/waterBalance";
let app,
    camera,
    scene,
    renderer,
    renderOrder = 0,
    modelUrlPath,
    lineMaterial,
    controls,
    direction = new THREE.Vector3(),
    clock,
    cityModel,
    imgMesh,
    speed = 0.2,
    waterpipe,
    meterdata,
    cameraCurve,
    linespeed = 0.0001,
    isCameraMove = false,
    isPaused = false,
    progress = 0,
    moveIndex = 0,
    cavasHtmlGroup = new THREE.Group(),
    meterGroup = new THREE.Group(),
    buildingGroup = new THREE.Group(),
    tubeGroup = new THREE.Group(),
    tubeOtherGroup = new THREE.Group(),
    buildingShaderGroup = new THREE.Group()


export default {
  name: "fddxjwxq",
  components: {
    bigLeft,
    bigRight,
    alarmRecord,
    meterTimeData,
    useTrends,
    waterBalance,
  },
  data() {
    return {
      // 小车移动状态
      isCarMove: false,
      currentItem: 1,
      isLoaded: false,
      akbutton: akbutton,
      loading: null,
      leftShow: false,
      rightShow: false,
      dataShow: false,
      movement: {
        forward: false,
        backward: false,
        left: false,
        right: false
      },
      user:{
        USER_ID:'',
        TOKEN:'',
        ip:'',
      }
    };
  },
  methods: {
    async initZThree() {
      app = new ZThree("screen");
      app.initThree();//
      app.initHelper();
      app.initMapControls();
      app.initLight(0.8);
      app.loaderSky("texture/sky4/");//

      window.app = app;
      camera = app.camera;
      scene = app.scene;
      renderer = app.renderer;
      controls = app.controls;
      controls.maxPolarAngle = Math.PI / 2.2; // 设置最大角度 防止 入地下
      controls.target = new THREE.Vector3(2, 44, -32);
      clock = new THREE.Clock();
      lineMaterial = new THREE.LineBasicMaterial({
        color: "#57d8ff",
        transparent: true,
        linewidth: 5,
        opacity: 1.0,
      });
      this.loadingModel();
      this.loadingTube();
// 点击精灵飞行
      app.initRaycaster((obj,point) => {
        // console.log([point.x,point.y,point.z])
        // console.log('new THREE.Vector3( '+point.x.toFixed(1)+', y, '+point.z.toFixed(1)+'),')
        // console.log([camera.position.x,camera.position.y,camera.position.z])
        // console.log([controls.target.x,controls.target.y,controls.target.z])
        var meteritem ;
        this.cleanPage();
        if(!obj)return;
        if(obj.useType&&obj.useType == "building"){
          buildingInfo.forEach((item) => {
            if (item.id == obj.name&&item.name!="") {
              this.rightShow = true;
              this.$refs["Right"].showPD(item.name,[item.info],item.name);
            }
          });
        }
        if(obj.type == "Mesh"&&obj.useType&&obj.useType == "waterTube"){
          tube.forEach((item) => {
            if (item.modelname == obj.name) {
              waterpipe.showData(item,[point.x,point.y,point.z]);
            }
          });
        }
        if (obj.isSprite) {
          buildingInfo.forEach((item) => {
            if (item.name === obj.name) {
              this.fuwei(item.cameraPosition,item.cameraControls);
            }
          });
        }

        if(obj.type === "Mesh"){
          meteritem = obj.parent;
        }else
          meteritem = obj;
        // console.log(meteritem)
        if(meteritem.useType&&meteritem.useType=='reditemMeter'
            &&meteritem.name.indexOf("Rectangle")==-1 &&meteritem.name!=''){
          if(meteritem.name.indexOf("-")>0){
            meterdata.changeMeter(meteritem,meteritem.name.split('-')[0]);
          }else {
            meterdata.changeMeter(meteritem,meteritem.name);
          }
        }
      });
      waterpipe = new waterPipe(scene);
      camera.position.set(-41, 1021, 312);
      this.fuwei();
      // 创建文本
      buildingInfo.forEach((item) => {
        if(item.name!=""){
          createSprite(cavasHtmlGroup, item.name, item.position);
        }
      });
      cavasHtmlGroup.renderOrder = renderOrder++;
      scene.add(cavasHtmlGroup);

      meterGroup.renderOrder = renderOrder++;
      scene.add(meterGroup);



      // // 绿色线贴图
      // let greenLineTexture = new THREE.TextureLoader().load("texture/green_line.png");
      // greenLineTexture.wrapS = greenLineTexture.wrapT = THREE.RepeatWrapping;
      // greenLineTexture.repeat.set(1, 1);
      // greenLineTexture.needsUpdate = true;
      //
      //
      // let tubeGreenMaterial = new THREE.MeshBasicMaterial({
      //   map: greenLineTexture,
      //   side: THREE.BackSide,
      //   transparent: true,
      // });
      // let lineTestGroup = new THREE.Group();
      // // 创建道路
      // let tubeTest = app.loaderTube(
      //     curveTest,
      //     tubeGreenMaterial
      // );
      // lineTestGroup.add(tubeTest);
      // scene.add(lineTestGroup);
      // // 创建水表
      // meters.forEach((item) => {
      //   app.loadMeter(meterGroup,item.type,item.meterId,item.position,item.rotateXYZ,3.5);
      // });
      const _that = this;
      // http://localhost:8020/#/fddxjwxq
      app.render(() => {
        controls.update(clock.getDelta());
        tubeGroup.traverse((model) => {
          if (model.type === "Mesh"&&model.useType == "waterTube") {
            model.material.map.offset.x -= 0.01;//
          }
        })
        if (isCameraMove&&!isPaused) {
          if (progress <1 ) {
            let point = cameraCurve.getPointAt(progress); //获取样条曲线指定点坐标，作为相机的位置
            const vectorAB = new THREE.Vector3(point.x-camera.position.x
                , point.y-camera.position.y, point.z-camera.position.z);
            const vectorBC = vectorAB.normalize().multiplyScalar(100)
            vectorBC.add(new THREE.Vector3(point.x, point.y, point.z))

            camera.position.set(point.x, point.y, point.z);
            controls.target.set(vectorBC.x, -20, vectorBC.z);
            // app.controls.update();
            progress += linespeed;
          }else {
            isCameraMove = false;
            isPaused = true;
            app.flyTo({
              position: [921, 266, 118],
              controls: [326,  -80,  239],
              duration: 1500,
            },callBack =>{
              _that.startAutoMove();
            });
          }
        }
        if(this.movement.backward||this.movement.forward||this.movement.left||this.movement.right){
          var cameraPosition = camera.position;
          var targetPosition = controls.target;
          direction.subVectors(targetPosition, cameraPosition);
          direction.normalize();
        }
        // 根据按键事件修改相机位置和方向
        if (this.movement.forward) {
          camera.position.x += direction.x * speed;
          camera.position.z += direction.z * speed;
          controls.target.x += direction.x * speed;
          controls.target.z += direction.z * speed;
          controls.update();
        }
        if (this.movement.backward) {
          camera.position.x -= direction.x * speed;
          camera.position.z -= direction.z * speed;
          controls.target.x -= direction.x * speed;
          controls.target.z -= direction.z * speed;
          controls.update();
        }
        if (this.movement.left) {
          controls.target.x += direction.z * speed;
          controls.target.z -= direction.x * speed;
          // console.log(controls.target)
          controls.update();
        }
        if (this.movement.right) {
          controls.target.x -= direction.z * speed;
          controls.target.z += direction.x * speed;
          controls.update();
        }
        renderer.render(scene, camera);
        TWEEN.update();
      });
    },
    lookMeter(waterId){
      this.cleanPage();
      if(isCameraMove){
        isPaused = true;
      }
      var meteritem ;
      //飞到水表位置
      tubeGroup.traverse((model) => {
        let name = model.name||'';
        if(name.indexOf("-")>0){
          name = name.split('-')[0];
        }
        if(model.type == "Group"){
          if (model.useType&&model.useType == "reditemMeter"&&waterId == name  ) {
            meteritem = model;
          }
        }
      })
      if(meteritem){
        app.flyTo({
          position: [meteritem.position.x,meteritem.position.y+6,meteritem.position.z+6],
          controls: [meteritem.position.x,meteritem.position.y-50,meteritem.position.z-50],
          duration: 1500,
        },callBack =>{
          this.showMeterData(meteritem)
          }
        );
      }
    },
    showMeterData(meteritem){
      if(meteritem.name.indexOf("-")>0){
        meterdata.changeMeter(meteritem,meteritem.name.split('-')[0]);
      }else {
        meterdata.changeMeter(meteritem,meteritem.name);
      }
    },
    buttonClick(item) {
      this.cleanPage();
      if(this.currentItem == item.index&&this.currentItem!=4&&this.currentItem!=1){
        this.fuwei();
        return;
      }
      this.currentItem = item.index;
      switch (item.index) {
        case 1:
          if(item.name=='复位系统'){
            isCameraMove = false;
            this.loadingShow();
            scene.remove(buildingGroup);
            scene.remove(buildingShaderGroup);
            scene.add(buildingGroup);
            this.loading.close();
            this.fuwei();
            item.name = '仿真漫游'
          }else {
            speed = item.speed;
            item.name = '复位系统'
            this.startAutoMove();
          }
          break;
        case 2:
          // 处理value为2的情况
          this.loadingShow();
          isCameraMove = false;
          if(buildingShaderGroup.children.length === 0){
            buildingGroup.traverse(function (child) {
              if (child.type=="Group") {
                if(child.name!=0){
                  let shaderModer =  child.clone();
                  shaderModer.traverse((model) => {
                    if (model.isMesh) {
                      app.shaderObj(model);
                      model.material.depthTest = false;//避免闪烁  材质深度不启用
                      model.renderOrder = renderOrder++;
                      shaderModer.renderOrder = renderOrder++;
                      buildingShaderGroup.add(shaderModer)
                    }
                  });
                }
              }
            });
          }
          buildingShaderGroup.renderOrder = renderOrder++;
          scene.remove(buildingGroup);
          scene.remove(buildingShaderGroup);
          scene.add(buildingShaderGroup);
          this.loading.close();
          this.fuwei([84,1028,199],[84,-80,195]);
          break;
        case 3:
          if(!this.dataShow){
            this.$refs["alarmRecord"].codeInit(this.user);
            this.$refs["meterTimeData"].codeInit(this.user);
            this.$refs["useTrends"].codeInit(this.user);
            this.$refs["waterBalance"].codeInit(this.user);
            this.dataShow = true;
          }else {
            this.dataShow = false;
          }

          break;
        case 4:
          // 处理value为4的情况
            if(isCameraMove){
              isPaused = !isPaused;
              break;
            }
            if(item.name=='步行模式'){
              app.flyTo({
                position: [558, 2, 500],
                controls: [558,-20, 400],
                duration: 1500,
              });
              item.name = '飞行模式'
            }else {
              this.fuwei();
              item.name = '步行模式'
            }
          break;
        default:
          // 处理value不为1-4的情况
          break;
      }
    },
    exportModel() {
      GLTFUtil.exporterGlb(cityModel);
    },
    fuwei(pos,cons){
      app.flyTo({
        position: pos||[921, 266, 118],
        controls: cons||[326,  -80,  239],
        duration: 1500,
      });
    },
    startAutoMove(){
      app.flyTo({
        position: [558, 2, 500],
        controls: [558,-20, 488],
        duration: 1500,
      },callBack =>{
        cameraCurve = curveTest;
        progress = 0;
        isCameraMove = true;
        isPaused = false;
      });
    },
    cleanPage(){
      this.rightShow = false;
      this.leftShow = false;
      waterpipe.deleteData();
      meterdata.deleteData();
      imgMesh&&scene.remove(imgMesh);
    },
    confirm(mag) {
      this.$message({
        message: mag,
        type: "success",
        duration: 3000,
      });
    },
    loadingShow() {
      this.loading = this.$loading({
        lock: true,
        text: "资源正在加载中，请稍后",
        spinner: "el-icon-loading",
        background: "rgba(0,0,0,0.1)",
      });
    },
    async loadingModel() {
      let apath = modelUrlPath ||"model/city/jwxq/glb/2.2g/";
      let sInt = 0;
      let eInt = 1;
      buildingGroup.renderOrder = renderOrder++;
      scene.add(buildingGroup);
      for (let i = sInt; i <= eInt; i += 1) {
        let itemModel = await app.loaderGlbDracoModel(
            apath,
            i
        );
        itemModel.traverse((model) => {
          if (model.isMesh) {
            model.frustumCulled = false;
            if (
                model.material &&
                model.material.map &&
                model.material.map.encoding
            ) {
              model.material.map.encoding = THREE.LinearEncoding;
              // model.material.depthTest = false;//避免闪烁  材质深度不启用
            }
          }
          model.name = i;
        });
        itemModel.renderOrder= renderOrder++;
        itemModel.name = i;
        buildingGroup.add(itemModel);
      }
      //楼宇
      let itemModel = await app.loaderGlbDracoModel(
          apath,
          "2"
      );
      itemModel.traverse((model) => {
        if (model.isMesh) {
          model.frustumCulled = false;
          if (
              model.material &&
              model.material.map &&
              model.material.map.encoding
          ) {
            model.material.map.encoding = THREE.LinearEncoding;
          }
          model.name = model.parent.name;
        }
        model.useType = "building";
      });
      itemModel.renderOrder= renderOrder++;
      itemModel.useType = "building";
      buildingGroup.add(itemModel);
      this.loading.close();
    },

    async loadingTube() {
      let apath = modelUrlPath ||"model/city/jwxq/glb/2b/";
      let addHeigth = 1;
      let redHeight = -0.5;
      tubeGroup.renderOrder = renderOrder++;
      tubeGroup.position.setY(addHeigth)
      scene.add(tubeGroup);


      //上水管道
      let itemModel = await app.loaderGlbDracoModel(
          apath,
          "5"
      );
      itemModel.traverse((model) => {
        if (model.isMesh) {
          model.frustumCulled = false;
          if (model.material ) {
            model.material  = app.loaderColorTube(false,50);
          }
          model.useType = "waterTube"
        }
      });
      itemModel.renderOrder= renderOrder++;
      // itemModel.name = "i";
      itemModel.useType = "waterTube";
      tubeGroup.add(itemModel);

      //上水管道 新加管道
      let itemModela = await app.loaderGlbDracoModel(
          apath,
          "a"
      );
      itemModela.traverse((model) => {
        if (model.isMesh) {
          model.frustumCulled = false;
          if (model.material ) {
            model.material  = app.loaderColorTube(false,50);
          }
          model.useType = "waterTube"
        }
      });
      itemModela.renderOrder= renderOrder++;
      // itemModel.name = "i";
      itemModela.useType = "waterTube";
      tubeGroup.add(itemModela);


      //上水管道
      let reditemModel = await app.loaderGlbDracoModel(
          apath,
          "6"
      );
      reditemModel.traverse((model) => {
        if (model.isMesh) {
          model.frustumCulled = false;
          if (model.material ) {
            model.material  = app.loaderColorTube(true,30);
          }
          model.useType = "waterTube"
        }
      });
      // console.log(itemModel)
      reditemModel.renderOrder= renderOrder++;
      reditemModel.name = "i";
      reditemModel.useType = "waterTube";
      reditemModel.position.setY(redHeight)
      tubeGroup.add(reditemModel);

      tubeOtherGroup.renderOrder = renderOrder++;
      tubeOtherGroup.position.setY(addHeigth)
      scene.add(tubeOtherGroup);
      //上水管道附件
      const blueMaterial = new THREE.MeshBasicMaterial({
        color: "#0077ff"
      }); // 创建蓝色材质

      let tubeOther = await app.loaderGlbDracoModel(
          apath,
          "4"
      );
      tubeOther.traverse((model) => {
        if (model.isMesh) {
          model.frustumCulled = false;
          if (model.material ) {
            model.material = blueMaterial;
          }
        }
      });
      tubeOther.renderOrder= renderOrder++;
      tubeOtherGroup.add(tubeOther);

      //上水管道附件
      const redblueMaterial = new THREE.MeshBasicMaterial({
        color: "#ff0015"
      }); // 创建蓝色材质

      let redtubeOther = await app.loaderGlbDracoModel(
          apath,
          "7"
      );
      redtubeOther.traverse((model) => {
        if (model.isMesh) {
          model.frustumCulled = false;
          if (model.material ) {
            model.material = redblueMaterial;
          }
        }
      });
      redtubeOther.renderOrder= renderOrder++;
      redtubeOther.position.setY(redHeight-0.1)
      tubeOtherGroup.add(redtubeOther);
      //消防栓
      // const xfsMaterial = new THREE.MeshBasicMaterial({
      //   color: "#ff0015",
      //   opacity:0.8
      //
      // });
      // //消防栓
      // let xfs = await app.loaderGlbDracoModel(
      //     apath,
      //     "8"
      // );
      // xfs.traverse((model) => {
      //   if (model.isMesh) {
      //     model.frustumCulled = false;
      //     if (model.material ) {
      //       model.material = xfsMaterial;
      //     }
      //   }
      // });
      // xfs.renderOrder= renderOrder++;
      // tubeOtherGroup.add(xfs);

      let reditemMeter = await app.loaderGlbDracoModel(
          apath,
          "aa"
      );
      reditemMeter.traverse((model) => {
        if (model.isMesh) {
          model.frustumCulled = false;
          model.parent.useType = "reditemMeter"
        }

      });
      reditemMeter.renderOrder= renderOrder++;
      reditemMeter.name = "i";
      reditemMeter.useType = "reditemMeter";
      // reditemMeter.position.setY(redHeight)
      tubeGroup.add(reditemMeter);

      let reditemMetere = await app.loaderGlbDracoModel(
          apath,
          "dd"
      );
      reditemMetere.traverse((model) => {
        if (model.isMesh) {
          model.frustumCulled = false;
          model.parent.useType = "reditemMeter"
        }

      });
      reditemMetere.renderOrder= renderOrder++;
      reditemMetere.name = "i";
      reditemMetere.useType = "reditemMeter";
      // reditemMeter.position.setY(redHeight)
      tubeGroup.add(reditemMetere);
    },

    // 处理按键按下事件
    onKeyDown(event) {
      switch (event.keyCode) {
        case 87: // w
          this.movement.forward = true;
          break;
        case 83: // s
          this.movement.backward = true;
          break;
        case 65: // a
          this.movement.left = true;
          break;
        case 68: // d
          this.movement.right = true;
          break;
      }
    },
    // 处理按键松开事件
    onKeyUp(event) {
      switch (event.keyCode) {
        case 87: // w
          this.movement.forward = false;
          break;
        case 83: // s
          this.movement.backward = false;
          break;
        case 65: // a
          this.movement.left = false;
          break;
        case 68: // d
          this.movement.right = false;
          break;
      }
    }
  },

  mounted() {
    this.loadingShow();
    this.baseNet = new BaseNet();
    this.baseNet.IsAgent(confirm);
    this.baseNet.getGPU(confirm);

    let ip = this.baseNet.getQueryString("IP");

    ip = ip.replaceAll("%3A", ":").replaceAll("%2F", "/");
    let USER_ID = this.baseNet.getQueryString("USER_ID");
    let TOKEN = this.baseNet.getQueryString("TOKEN");
    var success = this.baseNet.checkToken(ip,USER_ID,TOKEN);
    if(!success){
      // return;
    }
    this.user.ip = ip;
    this.user.USER_ID = USER_ID;
    this.user.TOKEN = TOKEN;

    meterdata = new meterData(this.user);
    // 监听按键事件
    window.addEventListener('keydown', this.onKeyDown, false);
    window.addEventListener('keyup', this.onKeyUp, false);
    this.initZThree();
  },
};
</script>
