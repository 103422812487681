import * as THREE from 'three'
import axios from "axios"
import $ from "@/assets/js/jquery.min";
// import JsZip from "jszip"
import {
  OrbitControls
} from 'three/examples/jsm/controls/OrbitControls.js';
import {
  MapControls
} from 'three/examples/jsm/controls/OrbitControls.js';
import {
  OBJLoader
} from 'three/examples/jsm/loaders/OBJLoader';
import {
  MTLLoader
} from 'three/examples/jsm/loaders/MTLLoader';
import {
  DRACOLoader
} from 'three/examples/jsm/loaders/DRACOLoader.js';
import {
  GLTFLoader
} from 'three/examples/jsm/loaders/GLTFLoader';
import {
  EffectComposer
} from 'three/examples/jsm/postprocessing/EffectComposer';
import {
  RenderPass
} from 'three/examples/jsm/postprocessing/RenderPass';
import {
  UnrealBloomPass
} from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import {
  CSS3DRenderer,CSS3DObject
} from 'three/examples/jsm/renderers/CSS3DRenderer';
import {Mesh} from "three";
import source from "echarts/src/data/Source";
// import fa from "element-ui/src/locale/lang/fa";

export default class ZThree {
  constructor(id) {
    this.id = id;
    this.el = document.getElementById(id);
  }

  // 初始化场景
  initThree() {
    let width = this.el.offsetWidth;
    let height = this.el.offsetHeight;
    this.scene = new THREE.Scene();
    this.textureLoader = new THREE.TextureLoader();
    this.camera = new THREE.PerspectiveCamera(45, width / height, 0.1, 10000)
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
      logarithmicDepthBuffer: true
    })
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(width, height)
    // this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.el.append(this.renderer.domElement)


    // this.renderer.setClearColor('#fff')

    // window.addEventListener('resize', function () {
    //   _this.camera.aspect = _this.el.offsetWidth / _this.el.offsetWidth;
    //   _this.camera.updateProjectionMatrix();
    //   _this.renderer.setSize(_this.el.offsetWidth, _this.el.offsetWidth);
    // }, false)
    window.onresize=function(){
      // 重置渲染器输出画布canvas尺寸
      // this.renderer.setSize(window.innerWidth,window.innerHeight);
      this.renderer.setSize(width, height);
      // 全屏情况下：设置观察范围长宽比aspect为窗口宽高比
      // this.camera.aspect = window.innerWidth/window.innerHeight;
      this.camera.aspect = width/height;
      // 渲染器执行render方法的时候会读取相机对象的投影矩阵属性projectionMatrix
      // 但是不会每渲染一帧，就通过相机的属性计算投影矩阵(节约计算资源)
      // 如果相机的一些属性发生了变化，需要执行updateProjectionMatrix ()方法更新相机的投影矩阵
      this.camera.updateProjectionMatrix ();
    };
  }
  initCSS3DObject(){
    this.cssScene = new THREE.Scene();
// 创建一个THREE.CSS3D渲染器
    this.cssRenderer = new CSS3DRenderer();
    this.cssRenderer.setSize(window.innerWidth, window.innerHeight);
    this.cssRenderer.domElement.style.position = 'absolute';
    this.cssRenderer.domElement.style.top = '0';
    // this.el.append(this.cssRenderer.domElement);
    document.body.appendChild(this.cssRenderer.domElement);

  }
  showHtmlUrl(){
    // https://blog.csdn.net/weixin_38527697/article/details/104991206
    const geometry = new THREE.PlaneBufferGeometry(20, 20);

// 创建一个网页纹理并应用于材质
    const texture = new THREE.TextureLoader().load('https://www.baidu.com');
    const material = new THREE.MeshBasicMaterial({ map: texture });

// 创建一个网页Mesh并添加到场景中
    const plane = new THREE.Mesh(geometry, material);
    plane.position.z = -1;
    this.cssScene.add(plane);

//创建一个CSS3DObject以在渲染中使用Web页面元素
    const element = document.createElement('div');
    element.innerHTML = 'Some content';
    const object = new CSS3DObject(element);
    object.position.z = 0;
    this.cssScene.add(object);
  }

  // 初始化helper
  initHelper() {
    // this.scene.add(new THREE.AxesHelper(1000))
  }

  // 初始化控制器
  initOrbitControls() {
    // let controls = new OrbitControls(this.camera, this.renderer.domElement)
    // controls.enableDamping = true
    // controls.enableZoom = true
    // controls.autoRotate = false
    // controls.autoRotateSpeed = 0.3
    // controls.enablePan = true
    // this.controls = controls

    let controls = new OrbitControls(this.camera, this.renderer.domElement);
    controls.enableDamping = true;
    controls.enableZoom = true;
    controls.autoRotate = false;
    // controls.autoRotateSpeed = 3;
    controls.enablePan = true;
    controls.enableKeys = true;
    // controls.keyPanSpeed = 7;
    controls.enableRotate = true;
    controls.maxPolarAngle = Math.PI / 2.2; // 设置最大角度 防止 入地下
    controls.keys = {
      LEFT: 37,
      UP: 38,
      RIGHT: 39,
      BOTTOM: 40
    }
    this.controls = controls;
  }
  // 初始化地图版控制器
  initMapControls() {
    let controls = new MapControls( this.camera, this.renderer.domElement );
    //controls.addEventListener( 'change', render ); // call this only in static scenes (i.e., if there is no animation loop)

    controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
    controls.dampingFactor = 0.05;

    controls.screenSpacePanning = false;

    controls.minDistance = 100;
    controls.maxDistance = 5000;
    controls.rotateSpeed =0.1;
    controls.maxPolarAngle = Math.PI / 2.0; // 设置最大角度 防止 入地下
    this.controls = controls;

  }

  // 初始化灯光
  initLight(intensity) {

    const ambientLight = new THREE.AmbientLight( '#fff' ,intensity||0.8);
    this.scene.add( ambientLight );

    const directionalLight = new THREE.DirectionalLight( 0xdddddd,0.8 );
    directionalLight.position.set( 1, 1, 1 ).normalize();
    this.scene.add( directionalLight );


    // let directionalLight = new THREE.DirectionalLight('#fff')
    // directionalLight.position.set(300, 300, 300).normalize()
    // this.scene.add(directionalLight)
    // let ambientLight = new THREE.AmbientLight('#fff', intensity||0.8)
    // this.scene.add(ambientLight)
    return {
      directionalLight,
      ambientLight
    }
    // let particleLight;
    // particleLight = new THREE.Mesh(
    //     new THREE.SphereGeometry( 4, 8, 8 ),
    //     new THREE.MeshBasicMaterial( { color: 0xffffff } )
    // );
    // this.scene.add( particleLight );
    //
    // particleLight.add( new THREE.PointLight( 0xffffff, 1 ) );
  }
  removeLight(){

  }
  // 初始化监测
  initStatus(Stat) {
    let stats = Stat ? new Stat() : new Stats()
    stats.setMode(0)
    stats.domElement.style.position = 'absolute'
    stats.domElement.style.left = '0px'
    stats.domElement.style.top = '0px'
    window.body.appendChild(stats.domElement)
    this.staus = stats
    return stats
  }
  mouseListener() {
    // 监听鼠标滚轮事件
    // let _that = this;
    // console.log("mouseListener")
    // // 监听鼠标左键点击事件
    // this.el.addEventListener('mousedown', event => {
    //  console.log("mousedown")
    // });
    //
    // this.el.addEventListener('mouseup', event => {
    //   console.log("mouseup")
    // });
  }
  // 初始化射线
  initRaycaster(callback) {
    this.raycaster = new THREE.Raycaster();

    // 绑定点击事件
    this.el.addEventListener("click", evt => {
      let mouse = {
        x: (evt.clientX / window.innerWidth) * 2 - 1,
        y: -(evt.clientY / window.innerHeight) * 2 + 1
      };
      // console.log(mouse)
      let activeObj = this.fireRaycaster(mouse);
      if (activeObj.point) {
        // console.log([activeObj.point.x, activeObj.point.y, activeObj.point.z]);
      }
      callback(activeObj.object,activeObj.point||[])
      //鼠标的变换
      document.body.style.cursor = "pointer";
      return activeObj;
    });
  }

  fireRaycaster(pointer) {
    // 使用一个新的原点和方向来更新射线
    this.raycaster.setFromCamera(pointer, this.camera);

    let intersects = this.raycaster.intersectObjects(this.scene.children, true);
    //
    if (intersects.length > 0) {
      let selectedObject = intersects[0];
      return selectedObject;
    } else {
      return false;
    }
  }

  // 创建包围盒
  loaderBoundingBox(object) {
    // 计算包围盒长宽高
    let Box = new THREE.Box3();
    Box.setFromObject(object);
    if (Box.isEmpty()) return;
    let min = Box.min;
    let max = Box.max;
    let width = max.x - min.x;
    let height = max.y - min.y;
    let deepth = max.z - min.z;

    // 计算包围盒中心点
    let centerX = (max.x + min.x) / 2;
    let centerY = (max.y + min.y) / 2;
    let centerZ = (max.z + min.z) / 2;

    // 创建包围盒实体
    let boxGeometry = new THREE.BoxGeometry(width, height, deepth);
    let boxMaterial = new THREE.MeshLambertMaterial({
      transparent: true,
      opacity: 0.5,
      color: 'red',
      depthTest: false
    })

    let box = new THREE.Mesh(boxGeometry, boxMaterial);
    box.position.set(centerX, centerY, centerZ);
    return box;
  }

  // 创建管道
  loaderTube(curve, tubeMaterial) {
    tubeMaterial = tubeMaterial || new THREE.MeshPhongMaterial({
      color: "rgb(45,245,216)",
      transparent: false,
      opacity: 1,
    });
    let tubeGeometry = new THREE.TubeGeometry(curve, Math.ceil(curve.getLength()), 1, 50, false);
    let tube = new THREE.Mesh(tubeGeometry, tubeMaterial);
    return tube;
  }
  // 创建用水管道
  loaderWaterTube(curve,name) {
    let textureLoader = new THREE.TextureLoader();
    // let tubeTexture = textureLoader.load("/texture/tubeRed.png");
    let tubeTexture = textureLoader.load("/texture/shuibjiantoublue.png");
    tubeTexture.wrapS = THREE.RepeatWrapping
    tubeTexture.wrapT = THREE.RepeatWrapping
    tubeTexture.repeat.y = 1;
    tubeTexture.repeat.x = curve.getLength() / 10;

    var tubeMaterial = new THREE.MeshBasicMaterial({
      map: tubeTexture,
      side: THREE.DoubleSide,//正反面显示
      needUpdate: true,
    });

    let tubeGeometry = new THREE.TubeGeometry(curve, Math.ceil(curve.getLength()), 0.2, 50, false);
    let tube = new THREE.Mesh(tubeGeometry, tubeMaterial);
    tube.jc_type = 'waterTube';
    tube.name = name || 'waterTube'+Math.random();
    return tube;
  }
  // 创建用水管道
  loaderColorTube(isRed,length) {
    let textureLoader = new THREE.TextureLoader();
    let tubeTexture;
    if(isRed){
      tubeTexture = textureLoader.load("/texture/tubeRed.png");
    }else {
      tubeTexture = textureLoader.load("/texture/shuibjiantoublue.png");
    }
    tubeTexture.wrapS = THREE.RepeatWrapping
    tubeTexture.wrapT = THREE.RepeatWrapping
    tubeTexture.repeat.y = 2;
    tubeTexture.repeat.x = length / 10;
    var tubeMaterial = new THREE.MeshBasicMaterial({
      map: tubeTexture,
      side: THREE.DoubleSide,//正反面显示
      needUpdate: true,
    });
    return tubeMaterial;
  }
  //创建喷泉
  createPenQuan(size, position) {
    position = position || [100, 10, 100];
    var ret = new THREE.Geometry();
    for (var p = 0; p < 500; p++) {
      var v = new THREE.Vector3(0, 0, 0);
      var dX, dY, dZ;
      dY = (Math.random() * size * 5) + size * 2.5;//10-30
      dX = (Math.random() * size) - size / 2;//-2-2
      dZ = (Math.random() * size) - size / 2;//-2-2
      v.velocity = new THREE.Vector3(dX, dY, dZ);
      ret.vertices.push(v);
    }
    for (var t = 0; t < 40; t++) {
      for (var tt = 0; tt < 500; tt++) {
        var ttv = ret.vertices[tt];
        ttv.x += ttv.velocity.x;
        ttv.y += ttv.velocity.y;
        ttv.z += ttv.velocity.z;
        ttv.velocity.y -= size / 8;
        if (ttv.y < size * 2.5) {
          ttv.x = ttv.y = ttv.z = 0;
          var ttdX, ttdY, ttdZ;
          ttdY = (Math.random() * size) + size * 2.5;
          ttdX = (Math.random() * size) - size / 2;
          ttdZ = (Math.random() * size) - size / 2;
          ttv.velocity = new THREE.Vector3(ttdX, ttdY, ttdZ);
        }
      }
    }
    const textureLoader = new THREE.TextureLoader();
    const sprite4 = textureLoader.load('texture/images/sprites/water04.png');
    const material = new THREE.PointsMaterial({ size: size * 2.5, map: sprite4, blending: THREE.AdditiveBlending, alphaTest: 0.5, transparent: true });
    material.color.setStyle("#AFEEEE");
    var pointCloud = new THREE.Points(ret, material);
    pointCloud.jc_type = 'penquan';
    pointCloud.name = 'penquan';
    pointCloud.position.set(position[0], position[1], position[2]);
    pointCloud.jc_size = size;
    return pointCloud;
  }
  //创建点位
  createPoints() {
    const vertices = [];
    const point1 = [-50, 0, 0]; // 点1坐标
    const point2 = [30, 0, 0]; // 点2坐标
    const controlPoint = [-20, 50, 0]; // 控制点坐标
    // 创建三维二次贝塞尔曲线
    const line1 = new THREE.QuadraticBezierCurve3(
      new THREE.Vector3(point1[0], point1[1], point1[2]),
      new THREE.Vector3(controlPoint[0], controlPoint[1], controlPoint[2]),
      new THREE.Vector3(point2[0], point2[1], point2[2])
    );

    vertices.push(0, 0, 0);

    const geometry = new THREE.BufferGeometry();
    const textureLoader = new THREE.TextureLoader();
    // const sprite4 = textureLoader.load( 'images/material/sprites/snowflake4.png' );
    // const sprite4 = textureLoader.load( 'images/material/sprites/water01.png' );
    const sprite4 = textureLoader.load('texture/images/sprites/water04.png');
    geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
    const material = new THREE.PointsMaterial({ size: 4, map: sprite4, blending: THREE.AdditiveBlending, depthTest: false, transparent: true });
    material.color.setStyle("#AFEEEE");
    const particles = new THREE.Points(geometry, material);
    let pypz = [];
    const dept = 10;
    for (var col = 0; col < 50; col++) {
      let py = dept * Math.random() - dept;
      let pz = dept * Math.random() - dept;
      pypz.push([py, pz])
    }
    particles.jc_type = 'penguan';
    particles.jc_pypz = pypz;
    particles.jc_line1 = line1;
    return particles;
  }
  // 加载扩散波
  loaderDiffusion(img, geometry) {
    let texture = new THREE.TextureLoader().load(img)
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping; //每个都重复
    texture.repeat.set(1, 1)
    texture.needsUpdate = true
    geometry = geometry || new THREE.CylinderGeometry(40, 40, 60, 64);
    let materials = [
      new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.DoubleSide,
        transparent: true
      }),
      new THREE.MeshBasicMaterial({
        transparent: true,
        opacity: 0,
        side: THREE.DoubleSide
      }),
      new THREE.MeshBasicMaterial({
        transparent: true,
        opacity: 0,
        side: THREE.DoubleSide
      })
    ]

    let mesh = new THREE.Mesh(geometry, materials)
    return mesh;
  }

  // 创建平面
  loaderPlane(path) {
    let textureLoader = new THREE.TextureLoader()
    let texture = textureLoader.load(path)
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
    // 设置重复次数
    texture.repeat.set(5000, 5000)
    let geometry = new THREE.PlaneGeometry(50000, 50000, 32);
    let material = new THREE.MeshBasicMaterial({
      map: texture, // 使用纹理贴图
      side: THREE.DoubleSide // 两面都渲染
    });
    let plane = new THREE.Mesh(geometry, material);
    plane.rotateX(Math.PI / 2)
    return plane;
  }

  // 加载obj模型
  loaderObjModel(path, objName, mtlName) {
    return new Promise(resolve => {
      if (!this.objLoader) {
        this.objLoader = new OBJLoader();
      }
      if (!this.mtlLoader) {
        this.mtlLoader = new MTLLoader();
      }
      this.mtlLoader
        .setPath(path)
        .load(mtlName + '.mtl', (materials) => {
          materials.preload();
          // 加载obj
          this.objLoader
            .setPath(path)
            .setMaterials(materials)
            .load(objName + '.obj', (object) => {
              resolve(object)
            }, function (xhr) {
              this.progress1 = (xhr.loaded / xhr.total) * 100 + "%";
              console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
            },);
        });
    })
  }
  // 加载obj模型
  loaderonlyObjModel(path, objName) {
    return new Promise(resolve => {
      if (!this.objLoader) {
        this.objLoader = new OBJLoader();
      }
      // 加载obj
      this.objLoader
        .setPath(path)
        .load(objName + '.obj', (object) => {
          resolve(object)
        });
    })
  }
  loadMeter(group,jcType ,meterId,site,rotateXYZ,scale){
    let apath = "model/meter/";
    let aName = "shuibiao04";
    let _that = this;
    if (!this.gltfLoader) {
      this.gltfLoader = new GLTFLoader();
    }
    if (!this.lineMaterial) {
      this.lineMaterial = new THREE.LineBasicMaterial({
        color: "#1d47c0",
        transparent: true,
        linewidth: 1,
        opacity: 0.5,
        needUpdate: true,
      });
    }
    this.gltfLoader.setPath(apath).load(aName + ".glb", function (glb) {
      var obj = glb.scene;
      obj.traverse(function(item) {
        if (item instanceof THREE.Mesh) {
          item.material = _that.lineMaterial;
        }
      });
      site&&obj.position.set(site[0],site[1],site[2]);
      obj.scale.set(scale||2,scale||2,scale||2);
      obj.jc_type = jcType;
      obj.meterId = meterId;
      rotateXYZ&&obj.rotateX(rotateXYZ[0]);
      rotateXYZ&&obj.rotateY(rotateXYZ[1]);
      rotateXYZ&&obj.rotateZ(rotateXYZ[2]);
      group.add(obj);
      return obj;
    })
  }
  showImg(imgPath,model){
    //加载并在模型旁边显示当前图像
    const textureLoader = new THREE.TextureLoader();
    textureLoader.setCrossOrigin("http://localhost:8080");
    const texture = textureLoader.load(imgPath);
    const geometry = new THREE.PlaneBufferGeometry(3, 3);
    const material = new THREE.MeshBasicMaterial({
      map: texture,
      side:THREE.DoubleSide
    });
    const mesh = new THREE.Mesh(geometry, material);

    mesh.position.copy(model.position);
    // mesh.position.x += 3; //将图像移动到模型的右侧
    mesh.position.y += 3; //将图像移动到模型的上方

    // 获取摄像头的方向向量和右方向向量
    const cameraDirection = this.camera.getWorldDirection(new THREE.Vector3());

    const cameraRight = new THREE.Vector3().crossVectors(cameraDirection, new THREE.Vector3(0, 1, 0)).normalize();
    // 将图片相对于模型的位置向右移动 (使用摄像头的右方向向量)
    mesh.position.add(cameraRight.clone().multiplyScalar(-4));

// 让模型面向摄像机方向，但让它保持与选择轴平行
    mesh.lookAt(this.camera.position);

    return mesh;
  }
  showImgAjax(imgPath,model){

    const image = new Image()
    // 解决跨域 Canvas 污染问题
    // image.setAttribute('crossOrigin', 'anonymous')
    image.src = imgPath
    image.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      const context = canvas.getContext('2d')
      context.drawImage(image, 0, 0, image.width, image.height)
      image.setAttribute('crossOrigin', 'anonymous')
      canvas.toBlob((blob) => {
        console.log(blob)
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.download = name || 'photo'
        a.href = url
        a.click()
        a.remove()
        URL.revokeObjectURL(url)
      })
    }

//     let imgData = null;
//     const _that = this;
//     $.ajax({
//       url: imgPath,
//       async: true,
//       method: "GET",
//       responseType: 'blob',
//       success: function(response){
//         console.log(response)
//         console.log(response.length)
//         // const img = new Image();
//         // img.onload = function () {
//         //   console.log(img)
//         // };
//         imgData = response;
//         //
//         // img.addEventListener('load', function () {
//         //   console.log('Image format:', this.width, this.height, this.type);
//         // });
//
//
//         const blob = new Blob([imgData], { type: 'image/jpeg' });
//         const url = URL.createObjectURL(blob);
//         const textureLoader = new THREE.TextureLoader();
//         const texture = textureLoader.load(url);
//         const geometry = new THREE.PlaneBufferGeometry(3, 3);
//         const material = new THREE.MeshBasicMaterial({
//           map: texture,
//           side:THREE.DoubleSide
//         });
//         const mesh = new THREE.Mesh(geometry, material);
//
//         mesh.position.copy(model.position);
//         // mesh.position.x += 3; //将图像移动到模型的右侧
//         mesh.position.y += 3; //将图像移动到模型的上方
//
//         // 获取摄像头的方向向量和右方向向量
//         const cameraDirection = _that.camera.getWorldDirection(new THREE.Vector3());
//
//         const cameraRight = new THREE.Vector3().crossVectors(cameraDirection, new THREE.Vector3(0, 1, 0)).normalize();
//         // 将图片相对于模型的位置向右移动 (使用摄像头的右方向向量)
//         mesh.position.add(cameraRight.clone().multiplyScalar(-4));
//
// // 让模型面向摄像机方向，但让它保持与选择轴平行
//         mesh.lookAt(_that.camera.position);
//         _that.scene.add(mesh);
//       },
//       error: function () {
//         console.error('图片加载失败');
//       }
//     });
  }
  // 下载含有url的文件
  downloadUrlFile(url, fileName,callBack) {
    let _that = this;
    const url2 = url.replace(/\\/g, '/');
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url2, true);
    xhr.responseType = 'blob';
    //xhr.setRequestHeader('Authorization', 'Basic a2VybWl0Omtlcm1pdA==');
    // 为了避免大文件影响用户体验，建议加loading
    xhr.onload = () => {
      if (xhr.status === 200) {
        // 获取文件blob数据并保存
        _that.saveAs(xhr.response, fileName);
      }
      let r = (xhr.loaded / xhr.total);
      r = r.toFixed(4)*100;
      callBack("首次加载文件下载中"+r);
    };
    xhr.send();
  }

  saveAs(data, name) {
    const urlObject = window.URL || window.webkitURL || window;
    const export_blob = new Blob([data]);
//createElementNS() 方法可创建带有指定命名空间的元素节点。
//此方法可返回一个 Element 对象。
    const save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
    save_link.href = urlObject.createObjectURL(export_blob);
    localStorage.setItem(name, save_link.href);
    save_link.download ="C:/"+ name;
    save_link.click();
  }
// 加载gltf+draco模型
  loaderGltfDracoModelOld(path, gltfName) {
    return new Promise(resolve => {
      if (!this.gltfLoader) {
        this.gltfLoader = new GLTFLoader();
        this.DRACOLoader = new DRACOLoader();
        this.DRACOLoader.setDecoderPath('draco/');
        this.gltfLoader.setDRACOLoader(this.DRACOLoader)
      }
      this.gltfLoader.setPath(path).load(gltfName + ".gltf", function (glb) {
        resolve(glb.scene)
      })
    })
  }

  // 加载glb+draco模型
  loaderGlbDracoModel(path, gltfName,callBack) {
    return new Promise(resolve => {
      if (!this.gltfLoader) {
        this.gltfLoader = new GLTFLoader();
      }
      if (!this.dracoLoader) {
        this.dracoLoader = new DRACOLoader();
        this.dracoLoader.setDecoderPath('draco/');
        this.gltfLoader.setDRACOLoader(this.dracoLoader)
      }
      this.gltfLoader.setPath(path).load(gltfName + ".glb", function (glb) {
        resolve(glb.scene)
      }, function (xhr) {
        let r = (xhr.loaded / xhr.total);
        r = r.toFixed(4);
        callBack&&callBack(r*100);
        // console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },)
    })
  }
  // 加载gltf+draco模型并压缩
  loaderGltfDracoModelAndPress(path, gltfName) {
    let _that = this;
    return new Promise(resolve => {
      if (!this.gltfLoader||!this.dracoLoader) {
        this.gltfLoader = new GLTFLoader();
        this.dracoLoader = new DRACOLoader();
        this.dracoLoader.setDecoderPath('draco/');
        this.gltfLoader.setDRACOLoader(this.dracoLoader)
      }
      this.gltfLoader.setPath(path).load(gltfName + ".gltf", function (glb) {
        // 获取模型
        console.log(glb.scene)
        const model = glb.scene;
        // 压缩模型
        const options = {
          compressionLevel: 7 // 设置压缩等级，0 - 10 之间
        };
        const compressedData = _that.dracoLoader.encodeDracoMesh(model.geometry, _that.dracoLoader.getDecoderModule(), options);

        // 将压缩后的数据应用到模型
        const compressedBufferGeometry = _that.dracoLoader.decodeDracoMesh(compressedData, _that.dracoLoader.getDecoderModule());
        model.geometry = compressedBufferGeometry;
        resolve(model)
      })
    })
  }
  loadgltfModel(url, name) {
    return new Promise(resolve => {
      if (!this.gltfLoader) {
        this.gltfLoader = new GLTFLoader();
      }
      this.gltfLoader.setPath(url).load(name + ".gltf", function (glb) {
        resolve(glb.scene)
      })
    })
  }
  loadGLBModel(url, name) {
    return new Promise(resolve => {
      if (!this.gltfLoader) {
        this.gltfLoader = new GLTFLoader();
      }
      this.gltfLoader.setPath(url).load(name + ".glb", function (glb) {
        resolve(glb.scene)
      })
    })
  }
  /**
   * 加载模型，并且循环处理
   * @param {*} Mesh 模型
   */
  loadUrlModel(url, name) {
    // let _that = this;
    // axios.get(url, {responseType: "arraybuffer"}).then(resp=>{
    //   let files = new window.File([resp.data], 'zipFile', {type: 'zip'})
    //   var new_zip = new JsZip();
    //   // 解压缩文件对象
    //   new_zip.loadAsync(files)
    //       .then(function(result) {
    //         // 压缩包的模型文件列表
    //         let fileList = result.files
    //         for(let key in fileList){
    //           // 读取模型文件内容
    //           new_zip.file(key).async("arraybuffer").then(content=>{
    //             // Blob构造文件地址，通过url加载模型
    //             let blob = new Blob([content])
    //             let modelUrl = URL.createObjectURL(blob)
    //             _that.loadGLBModel([modelUrl], name);
    //           })
    //         }
    //       });
    // })
  }
  // 加载天空盒
  loaderSky(path) {
    let skyTexture = new THREE.CubeTextureLoader()
      .setPath(path)
      .load([
        "px.jpg", //右
        "nx.jpg", //左
        "py.jpg", //上
        "ny.jpg", //下
        "pz.jpg", //前
        "nz.jpg" //后
      ]);

    this.scene.background = skyTexture;
    this.renderer.setClearAlpha(1);
    return skyTexture;
  }

  // 加载粒子背景特效
  laoderPartcle(range = 1000, number = 1000, imgUrl) {
    let texture = new THREE.TextureLoader().load(imgUrl);
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(1, 1);
    texture.needsUpdate = true;
    let geometry = new THREE.Geometry();
    let material = new THREE.PointsMaterial({
      size: 6,
      map: texture, // 纹理
      // color: 'blue',
      transparent: true, // 透明
      opacity: 1, // 透明度
      // depthTest: false, // 可以去掉texture的黑色背景
      blending: THREE.AdditiveBlending // 融合模式
    });
    for (let i = 0; i < number; i++) {
      let v = new THREE.Vector3(
        Math.random() * range - range / 2,
        Math.random() * range - range / 2,
        Math.random() * range - range / 2
      );
      v.velocityY = 0.1 + Math.random() / 5;
      v.velocityX = (Math.random() - 0.5) / 3;
      v.velocityZ = (Math.random() - 0.5) / 3;
      geometry.vertices.push(v);
    }
    let points = new THREE.Points(geometry, material);
    return points;
  }
// showText
  showText(cavasHtmlGroup,jcType,site) {
    var text = "Hello, World!Hello, WorldHello, WorldHello, WorldHello, WorldHello, WorldHello, WorldHello, World";
    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');

    canvas.width = 100;
    canvas.height = 60;
    context.fillStyle = "#ffffff"; // 设置背景色为白色
    context.fillRect(0, 0, canvas.width, canvas.height); // 绘制白色矩形作为背景
    context.font = "Bold 12px Arial";
    context.fillStyle = "#ff0000";
    context.fillText(text, 10, 10);

    var atexture = new THREE.Texture(canvas);
    atexture.needsUpdate = true;
    console.log(atexture)
    var textMaterial = new THREE.MeshBasicMaterial( {map: atexture} );
    var textMesh = new THREE.Mesh(new THREE.PlaneGeometry(100, 60), textMaterial);
    textMesh.position.set(0, 0, 0.1);


// 将平面和文字合并成一个mesh对象
    var aplane = new THREE.PlaneGeometry(100, 60);
    var amaterial = new THREE.MeshBasicMaterial({color: 0xffffff, side: THREE.DoubleSide});
    var amesh = new THREE.Mesh(aplane, amaterial);
    amesh.add(textMesh)
    amesh.jc_type = jcType||'DataText';
    amesh.name = jcType||'DataText';
    site&&amesh.position.set(site[0],site[1],site[2]);
    cavasHtmlGroup.add(amesh);
    return amesh
  }
  // 加入炫光通道
  bloomComposer() {
    let renderScene = new RenderPass(this.scene, this.camera)
    //创建 bloomPass
    let bloomPass = new UnrealBloomPass(new THREE.Vector2(this.el.offsetWidth, this.el.offsetHeight), 1.5, 0.4, 0.85);
    bloomPass.renderToScreen = true;
    bloomPass.threshold = 0;
    bloomPass.strength = 0.6;
    bloomPass.radius = 0.2;

    //创建 EffectComposer
    let bloomComposer = new EffectComposer(this.renderer)
    bloomComposer.setSize(this.el.offsetWidth, this.el.offsetHeight);
    bloomComposer.addPass(renderScene);
    // 眩光通道bloomPass插入到composer
    bloomComposer.addPass(bloomPass)

    bloomComposer.render()

    return bloomComposer;
  }

  // 相机飞行
  flyTo(option,callBack) {
    option.position = option.position || [] // 相机新的位置
    option.controls = option.controls || [] // 控制器新的中心点位置(围绕此点旋转等)
    option.duration = option.duration || 1000 // 飞行时间
    option.easing = option.easing || TWEEN.Easing.Linear.None
    TWEEN.removeAll()
    const curPosition = this.camera.position
    const controlsTar = this.controls.target
    const tween = new TWEEN.Tween({
      x1: curPosition.x, // 相机当前位置x
      y1: curPosition.y, // 相机当前位置y
      z1: curPosition.z, // 相机当前位置z
      x2: controlsTar.x, // 控制当前的中心点x
      y2: controlsTar.y, // 控制当前的中心点y
      z2: controlsTar.z // 控制当前的中心点z
    }).to({
      x1: option.position[0], // 新的相机位置x
      y1: option.position[1], // 新的相机位置y
      z1: option.position[2], // 新的相机位置z
      x2: option.controls[0], // 新的控制中心点位置x
      y2: option.controls[1], // 新的控制中心点位置x
      z2: option.controls[2] // 新的控制中心点位置x
    }, option.duration).easing(TWEEN.Easing.Linear.None) // TWEEN.Easing.Cubic.InOut //匀速
    tween.onUpdate(() => {
      this.controls.enabled = false
      this.camera.position.set(tween._object.x1, tween._object.y1, tween._object.z1)
      this.controls.target.set(tween._object.x2, tween._object.y2, tween._object.z2)
      this.controls.update()
      if (option.update instanceof Function) {
        option.update(tween)
      }
    })
    tween.onStart(() => {
      this.controls.enabled = false
      if (option.start instanceof Function) {
        option.start()
      }
    })
    tween.onComplete(() => {
      this.controls.enabled = true
      if (option.done instanceof Function) {
        option.done()
      }
      callBack&&callBack();
    })
    tween.onStop(() => option.stop instanceof Function ? option.stop() : '')
    tween.start()
    TWEEN.add(tween)
    return tween
  }
  // animateCamera(current1, target1, current2, target2) {
  //   var tween = new TWEEN.Tween({
  //     x1: current1.x, // 相机当前位置x
  //     y1: current1.y, // 相机当前位置y
  //     z1: current1.z, // 相机当前位置z
  //     x2: current2.x, // 控制当前的中心点x
  //     y2: current2.y, // 控制当前的中心点y
  //     z2: current2.z // 控制当前的中心点z
  //   });
  //   tween.to({
  //     x1: target1.x, // 新的相机位置x
  //     y1: target1.y, // 新的相机位置y
  //     z1: target1.z, // 新的相机位置z
  //     x2: target2.x, // 新的控制中心点位置x
  //     y2: target2.y, // 新的控制中心点位置x
  //     z2: target2.z // 新的控制中心点位置x
  //   }, 1000);
  //   tween.onUpdate(function () {
  //     camera.position.x = this.x1;
  //     camera.position.y = this.y1;
  //     camera.position.z = this.z1;
  //     orbit.target.x = this.x2;
  //     orbit.target.y = this.y2;
  //     orbit.target.z = this.z2;
  //     orbit.update();
  //   })
  //   tween.easing(TWEEN.Easing.Cubic.InOut);
  //   tween.start();
  // }
// 模型移动 target // 物体变动后的位置
  meshMove(cmesh,target) {
    TWEEN.removeAll();
    var position =  cmesh.position // 物体变动前的位置
    var tween = new TWEEN.Tween(position).to(target, 2000).easing(TWEEN.Easing.Quadratic.InOut)
    tween.onUpdate(() => {
      cmesh.position.x = position.x
      cmesh.position.y = position.y
      cmesh.position.z = position.z
    })
    tween.start()
    return tween
  }
  // html转canvas
  addHtmlCanvas(html2canvas, option) {
    option.parent = option.parent || this.scene
    option.scale = option.scale || [1, 1, 1]
    option.position = option.position || [0, 0, 0]
    document.body.insertAdjacentHTML('beforeend', option.element)
    const element = document.body.lastChild
    element.style.zIndex = -1
    html2canvas(element, {
      backgroundColor: 'transparent'
    }).then(canvas => {
      option.position = option.position || [0, 0, 0]
      let html
      if (option.type === 'plane') {
        html = new THREE.Mesh(new THREE.PlaneBufferGeometry(1, 1, 10), new THREE.MeshStandardMaterial({
          map: new THREE.CanvasTexture(canvas),
          blending: THREE.AdditiveBlending,
          transparent: true,
          side: THREE.DoubleSide
        }))
      } else {
        html = new THREE.Sprite(new THREE.SpriteMaterial({
          map: new THREE.CanvasTexture(canvas),
          blending: THREE.AdditiveBlending,
          transparent: true,
          sizeAttenuation: option.sizeAttenuation || true
        }))
      }
      html.scale.set(option.scale[0], option.scale[1], option.scale[2])
      html.position.set(option.position[0], option.position[1], option.position[2])
      html.name = option.name || 'canvas-sprite'

      option.parent.add(html)
      document.body.removeChild(element)
      if (option.callback) option.callback(html)
    })
  }

  // 设置加载模型定位到原点
  setObjectContent(object) {
    object.updateMatrixWorld();
    // 获得包围盒得min和max
    const box = new THREE.Box3().setFromObject(object);
    // getSize 返回包围盒的宽度，高度，和深度
    // .length() 计算从(0, 0, 0) 到 (x, y, z)的欧几里得长度 （Euclidean length，即直线长度）
    const boxSize = box.getSize();
    // getCenter 返回包围盒的中心点 Vector3。
    const center = box.getCenter(new THREE.Vector3());
    object.position.x += object.position.x - center.x;
    object.position.y += object.position.y - center.y;
    object.position.z += object.position.z - center.z;

    // 自动设置相机位置，可用可不用
    this.camera.position.copy(center);
    if (boxSize.x > boxSize.y) {
      this.camera.position.z = boxSize.x * -2.85;
    } else {
      this.camera.position.z = boxSize.y * -2.85;
    }
    this.camera.lookAt(0, 0, 0);
  }

  getshader(){
    const res = {};
    res.vertexShader = 'varying vec3 vNormal;\n' +
        'varying vec3 vPositionNormal;\n' +
        'void main()\n' +
        '{\n' +
        '    vNormal = normalize( normalMatrix * normal ); \n' +
        '    vPositionNormal = normalize(( modelViewMatrix * vec4(position, 1.0) ).xyz);\n' +
        '    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );\n' +
        '}';
    res.fragmentShader = 'uniform vec3 glowColor;\n' +
        'uniform float b;\n' +
        'uniform float p;\n' +
        'uniform float s;\n' +
        'varying vec3 vNormal;\n' +
        'varying vec3 vPositionNormal;\n' +
        'void main()\n' +
        '{\n' +
        '    float a = pow( b + s * abs(dot(vNormal, vPositionNormal)), p );\n' +
        '    gl_FragColor = vec4( glowColor, a );\n' +
        '}'
    res.uniforms = {
      "s": {
        type: "f",
        value: -1.0
      },
      "b": {
        type: "f",
        value: 1.0
      }, //bias 颜色最亮的位置
      "p": {
        type: "f",
        value: 1.0
      }, //power决定了透明度变化速度及方向。
      glowColor: {
        type: "c",
        value: new THREE.Color(0x1DA9FC)
      }
    }
    return  res;
  }
  // 添加效果对象
  shaderObj(mesh) {
    const customMaterial = new THREE.ShaderMaterial({
      uniforms: {
        "s": {
          type: "f",
          value: -1.0
        },
        "b": {
          type: "f",
          value: 1.0
        }, //bias 颜色最亮的位置
        "p": {
          type: "f",
          value: 1.0
        }, //power决定了透明度变化速度及方向。
        glowColor: {
          type: "c",
          value: new THREE.Color(0x1DA9FC)
        }
      },
      vertexShader: 'varying vec3 vNormal;\n' +
          'varying vec3 vPositionNormal;\n' +
          'void main()\n' +
          '{\n' +
          '    vNormal = normalize( normalMatrix * normal ); \n' +
          '    vPositionNormal = normalize(( modelViewMatrix * vec4(position, 1.0) ).xyz);\n' +
          '    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );\n' +
          '}',
      fragmentShader:'uniform vec3 glowColor;\n' +
          'uniform float b;\n' +
          'uniform float p;\n' +
          'uniform float s;\n' +
          'varying vec3 vNormal;\n' +
          'varying vec3 vPositionNormal;\n' +
          'void main()\n' +
          '{\n' +
          '    float a = pow( b + s * abs(dot(vNormal, vPositionNormal)), p );\n' +
          '    gl_FragColor = vec4( glowColor, a );\n' +
          '}',
      side: THREE.FrontSide,
      blending: THREE.AdditiveBlending,
//       除了AdditiveBlending，three.js中的blending还可以设置为以下几种类型：
//
// - NormalBlending：正常的alpha混合
//     - NoBlending：不混合
//     - CustomBlending：自定义混合，需要自己设置混合因子和混合方程式
//     - SubtractiveBlending：减法混合
//     - MultiplyBlending：乘法混合
//     - AdditiveAlphaBlending：使用alpha值进行加法混合
//     - MultiplyAlphaBlending：使用alpha值进行乘法混合
//     - CustomBlending：自定义混合，需要自己设置混合因子和混合方程式
//
//     blending类型的设置可以通过Material的blending属性进行设置。
      transparent: true,
      opacity: 0.4
    })
    mesh.material = customMaterial;
  }
  // 添加效果对象
  shaderObjBlue(mesh) {
    const material = new THREE.ShaderMaterial({
      uniforms: {
        color: { value: new THREE.Color(0x0088ff) },
        opacity: { value: 0.5 },
        size: { value: 1.0 },
      },
      vertexShader: `
        uniform float size;
        varying vec3 vPosition;

        void main() {
          vPosition = position;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
          gl_PointSize = size;
        }
      `,
      fragmentShader: `
        uniform vec3 color;
        uniform float opacity;
        varying vec3 vPosition;

        void main() {
          float distance = length(vPosition);
          gl_FragColor = vec4(color, opacity - distance * 0.05);
        }
      `,
    });
    mesh.material = material;
  }
  // 边界增加模糊效果
  linebianjie() {
    // 在模型和场景的交界处添加渐变效果
    const textureLoader = new THREE.TextureLoader();
    let jjloader = textureLoader.load("texture/caodi.jpg");
    var jjgeometry = new THREE.PlaneGeometry(50000, 50000, 1, 1);
    var jjmaterial = new THREE.MeshBasicMaterial({
      map:jjloader, // 渐变贴图
      transparent: true,
      opacity: 0.5
    });
    var jjplane = new THREE.Mesh(jjgeometry, jjmaterial);
    jjplane.rotation.x = -Math.PI / 2;
    jjplane.position.y = -2;
    // scene.add(jjplane);
  }
  // 渲染
  render(callback) {
    callback()
    requestAnimationFrame(() => this.render(callback))
  }

  clearGroup(group) {
    const clearCache = (item) => {
      item.geometry.dispose();
      item.material.dispose();
    };
    const removeObj = (obj) => {
      let arr = obj.children.filter((x) => !!x);
      arr.forEach((item) => {
        if (item.children.length) {
          removeObj(item);
        } else {
          clearCache(item);
          item.clear();
        }
      });
      obj.clear();
      arr = null;
    };
    removeObj(group);
  }
  /**
 * 移除组件
 * @param {Object} name 组件名称
 */
  removeMesh(name) {
    let _that = this;
    let nodes  = [];
    this.scene.traverse(function (node) {
      if (node.name === name) {
        nodes.push(node);
      }
    });
    nodes.forEach(item=>{
      item.parent.remove(item);
      _that.clearGroup(item);
    })
  }
  /**
   * 移除组件
   * @param {Object} name 组件名称
   */
  removeMeshByMesh(mesh) {
    mesh.parent.remove(mesh);
    this.clearGroup(mesh);
  }

}
