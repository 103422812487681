<template>
  <div class="left animated slideInLeft">
    <div class="title1"><font face="楷体">设备状态</font></div>
    <div class="box">
      <div class="data">
        <div class="bg">
          <img :src="img_src" />
          <div class="title">设备总数</div>
          <div class="total">{{ devices_total_num }}</div>
        </div>
        <div class="on_line">
          <img :src="on_src" />
          <div class="normal">正常</div>
          <div class="normal_num">{{ devices_online_num }}</div>
        </div>
        <div class="off_line">
          <img :src="off_src" />
          <div class="off">离线</div>
          <div class="off_num">{{ devices_offline_num }}</div>
        </div>
      </div>
      <div class="bg">
        <img :src="img_src" />
        <div class="title">日用量</div>
      </div>
      <div
        ref="parentElement"
        style="width: 100%; overflow: hidden; margin-top: -1.5rem"
        id="parentWidth"
      >
        <JCBarChart
          ref="barChart"
          @set-parent-width="parentWidth = $event"
        ></JCBarChart>
      </div>
    </div>
  </div>
</template>

<script>
import JCBarChart from "@/components/chart/JCbarChart";
import $ from "@/assets/js/jquery.min.js";

export default {
  components: { JCBarChart },
  name: "Left",
  data() {
    return {
      img_src: require("./../assets/images/right.png"),
      on_src: require("./../assets/images/online_icon.png"),
      off_src: require("./../assets/images/offline_icon.png"),
      devices_total_num: 0,
      devices_online_num: 0,
      devices_offline_num: 0,
      barData: null,
      USER_ID: "",
      url: "",
      TOKEN: "",
    };
  },
  created() {},
  mounted() {
    // alert();
    // this.init(this.url, this.TOKEN, this.USER_ID);
  },
  methods: {
    codeInit(ip, userId, token) {
      this.USER_ID = userId || this.USER_ID;
      this.url = ip || this.url;
      this.TOKEN = token || this.TOKEN;
      this.showData();
    },
    // 方法一
    // 加载显示设备状态、用水曲线
    showData() {
      this.getdata();
      this.getnum();
    },
    getdata() {
      var that = this;
      var xdata = [];
      var ydata = [];
      $.get(
        that.url + "/apphome/meterLevelDayDataList",
        {
          USER_ID: that.USER_ID,
          TOKEN: that.TOKEN,
          START_DATA_DATE: that.gettomorrowDate(-7),
          END_DATA_DATE: that.gettomorrowDate(0),
          METER_NUM: 1,
        },
        function (data) {
          if (data.INFO == "success") {
            $.each(data.waterTimeList, function (index, value) {
              xdata.push(value.DATA_DATE.substr(5, value.DATA_DATE.length));
              ydata.push(value.DAY_USE_SUM);
            });
            that.barData = {
              xAxisDatas: xdata,
              yAxisDatas: [
                {
                  name: "日用水量",
                  data: ydata,
                },
              ],
            };
            JCBarChart.methods.showChartData(xdata, ydata);
          } else {
            console.log("token失效");
          }
        }
      );
    },
    getnum() {
      var that = this;
      $.get(
        that.url + "/apphome/waterTime",
        {
          USER_ID: that.USER_ID,
          TOKEN: that.TOKEN,
          showCount: 999,
          currentPage: 1,
        },
        function (data) {
          var online_num = 0;
          var offline_num = 0;
          if (data.INFO == "success") {
            that.devices_total_num = data.waterTimeList.length;
            $.each(data.waterTimeList, function (index, value) {
              if (
                that.timestampToTime(value.LAST_UPDATE_DATE) >=
                that.gettomorrowDate(-3)
              ) {
                online_num++;
              } else {
                offline_num++;
              }
            });
            that.devices_online_num = online_num;
            that.devices_offline_num = offline_num;
          } else {
            console.log("token失效");
          }
        }
      );
    },
    //时间戳转换方法  功能:添加编辑时间选择显示 "-"
    timestampToTime: function (date) {
      var now = new Date(parseInt(date)),
        y = now.getFullYear(),
        m = now.getMonth() + 1,
        d = now.getDate();
      return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
    },
    gettomorrowDate: function (AddDayCount) {
      var dd = new Date();
      dd.setDate(dd.getDate() + AddDayCount); //获取AddDayCount天后的日期
      var y = dd.getFullYear();
      var m = dd.getMonth() + 1; //获取当前月份的日期
      if (m.toString().length == 1) {
        //如果月份是一位数则拼接"g"m = "0" + m;
        m = "0" + m;
      }
      var d = dd.getDate();
      if (d < 10) {
        return y + "-" + m + "-0" + d;
      } else {
        return y + "-" + m + "-" + d;
      }
    },
    //获取echarts 父级的宽度
    // getdom() {
    //   this.$refs["barChart"].getdom();
    // },
  },
};
</script>
<style lang='less' scoped>
.left {
  width: 100%;
  height: 100%;
  font-family: Microsoft Yahei;
  color: #fff;
  font-size: 16px;
  .title1 {
    width: 95%;
    font-size: 20px;
    font-weight: 600;
    height: 50px;
    line-height: 50px;
    background-image: url("./../assets/images/title.png");
    background-size: 100% 100%;
    padding-left: 5%;
  }
  .data {
    padding: 1rem 1rem 1rem 0.5rem;
    .on_line {
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-left: 3%;
      margin-top: 0.8rem;
      width: 97%;
      height: 2.5rem;
      background-image: url("./../assets/images/on_line.png");
      position: relative;
      display: flex;
      flex-direction: row;
      img {
        height: 1.5rem;
        width: 1.5rem;
        margin: 0.5rem 1rem;
      }
      .normal {
        font-weight: 800;

        width: 13rem;
        height: 40px;
        line-height: 40px;
        text-align: left;
      }
      .normal_num {
        color: #3ee5ed;
        font-weight: 800;
        font-size: 28px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        float: right;
        margin: 0 0.4rem;
      }
    }
    .off_line {
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-left: 3%;
      margin-top: 0.8rem;
      width: 97%;
      height: 2.5rem;
      background-image: url("./../assets/images/off_line.png");
      position: relative;
      display: flex;
      flex-direction: row;
      img {
        height: 1.5rem;
        width: 1.5rem;
        margin: 0.5rem 1rem;
      }
      .off {
        font-weight: 800;
        width: 13rem;
        height: 40px;
        line-height: 40px;
        text-align: left;
      }
      .off_num {
        color: #f1d2e4;
        font-weight: 800;
        font-size: 28px;
        height: 40px;
        line-height: 40px;
        /* width: 222px; */
        text-align: center;
        float: right;
        margin: 0 0.4rem;
      }
    }
  }
}
.box {
  background-image: url("./../assets/images/box.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 85%;
}

.bg {
  display: flex;
  flex-direction: row;
  // padding: 1rem .5rem;
  img {
    height: 40px;
    width: 40px;
    vertical-align: middle;
  }
  .total {
    color: aqua;
    font-weight: bold;
    font-size: 24px;
    margin: 0.2rem 0.5rem;
  }
  .title {
    width: 4rem;
    font-weight: 500;
    height: 40px;
    line-height: 40px;
  }
}
</style>
