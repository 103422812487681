import * as THREE from 'three';
/**
 * 水表管网数据控件
 */
function waterPipe(scene) {

    this.Sprites = [];
    this.count = 200;
    this.scene = scene;
    this.gColor = '#28f260'
    this.prop1 = {
        width: 352,
        height: 148,
        pos: [0, 0, 0],
        scale: [0.020625 * this.count, 0.011484375 * this.count, 0],
    }
    this.tab1 = [
        ['管道口径：', ''],
        ['管道材质：', ''],
        ['', '' ],
    ]
    this.props = [this.prop1]
    this.tabs = [this.tab1]
}

Object.assign(waterPipe.prototype, {
    constructor: waterPipe,
    showData:function (tube,pos){
        this.clearSprite();
        // this.tab1[2][1] = tube.name;
        this.tab1[0][1] = tube.model;
        this.tab1[1][1] = tube.matera;
        this.prop1.pos = pos;
        this.initSprite();
    },
    deleteData:function (){
        this.clearSprite();
    },
    initSprite: function() {
        const borderColor = 'rgba(39, 179, 236, 1)'
        const color = 'rgba(0,0,0, 1)'
        this.makeTextSprite1(this.tabs, this.props, {
            color: color,
            borderColor,
            backgroundColor: 'rgba(39, 179, 236,0.3)'
        });

    },
    // 清空雪碧图
    clearSprite: function() {
        for(let i=0; i<this.Sprites.length; i++) {
            let parent = this.Sprites[i].parent;
            parent.remove(this.Sprites[i]);
            this.Sprites[i].geometry.dispose();
            this.Sprites[i].material.dispose();
        }
        this.Sprites = [];
    },
        /* 创建字体精灵 */
    makeTextSprite1: function(tabs, props, parameters) {
        if (parameters === undefined) parameters = {}
        // const _that = this;
        tabs.forEach((tab, k) => {
            let {
                width,
                height
            } = props[k]
            /* 创建画布 */
            let canvas = document.createElement('canvas');
            let context = canvas.getContext('2d')
            canvas.width = width
            canvas.height = height
            let gap = 10
            let fontface = parameters.hasOwnProperty("fontface") ? parameters["fontface"] : "sans-serif"
            /* 字体大小 */
            let fontsize = parameters.hasOwnProperty("fontsize") ?
                parameters["fontsize"] : 30

            let color = parameters.hasOwnProperty("color") ? parameters["color"] : 'rgba(0, 0, 0, 1.0)'
            /* 边框厚度 */
            let borderWith = parameters.hasOwnProperty("borderWith") ? parameters["borderWith"] : 2
            /* 边框颜色 */
            // eslint-disable-next-line no-prototype-builtins
            let borderColor = parameters.hasOwnProperty("borderColor") ? parameters["borderColor"] : {
                r: 0,
                g: 0,
                b: 0,
                a: 1.0
            }
            /* 背景颜色 */
            let backgroundColor = parameters.hasOwnProperty("backgroundColor") ? parameters["backgroundColor"] : {
                r: 255,
                g: 255,
                b: 255,
                a: 1.0
            }
            /* 字体加粗 */
            // context.font = "Bold " + fontsize + "px " + fontface
            context.font = fontsize + "px " + fontface
            let unit = gap + fontsize
            /* 背景颜色 */
            context.fillStyle = backgroundColor
            /* 边框的颜色 */
            context.strokeStyle = borderColor
            context.lineWidth = borderWith
            /* 绘制圆角矩形 */
            this.roundRect(context, gap, gap, width - gap, height - gap, 4)

            tab.forEach((d, i) => {
                context.fillStyle = color;
                context.fillText(d[0], gap * 2, gap + unit * (i + 1))
                if (d[2]) {
                    context.fillStyle = d[2]
                }
                context.fillText(d[1], gap * 2 + this.measureText(d[0], context), gap + unit * (i + 1))
            })

            /* 画布内容用于纹理贴图 */
            let texture = new THREE.Texture(canvas);
            texture.needsUpdate = true
            let spriteMaterial = new THREE.SpriteMaterial({
                map: texture,
                // sizeAttenuation:false,
                // transparent:true
            });
            let sprite = new THREE.Sprite(spriteMaterial)
            // console.log(sprite.spriteMaterial)
            /* 缩放比例 */
            sprite.scale.set(...props[k].scale)
            sprite.center = new THREE.Vector2(0, 0);
            sprite.jc_type = 'waterPipe'
            this.scene.add(sprite);
            this.Sprites.push(sprite);
            sprite.position.set(...props[k].pos);
        })
    },

    measureText: function(text, ctx, font) {
        if (font) ctx.font = font
        return ctx.measureText(text).width;
    },
    /* 绘制圆角矩形 */
    roundRect: function(ctx, x, y, w, h, r) {

        ctx.beginPath();
        ctx.moveTo(x + r, y);
        ctx.lineTo(x + w - r, y);
        ctx.quadraticCurveTo(x + w, y, x + w, y + r);
        ctx.lineTo(x + w, y + h - r);
        ctx.quadraticCurveTo(x + w, y + h, x + w - r, y + h);
        ctx.lineTo(x + r, y + h);
        ctx.quadraticCurveTo(x, y + h, x, y + h - r);
        ctx.lineTo(x, y + r);
        ctx.quadraticCurveTo(x, y, x + r, y);
        ctx.closePath();

        // ctx.shadowColor = "#qb95cf";
        // ctx.shadowOffsetX = 0;
        // ctx.shadowOffsetY = 0;
        // ctx.shadowBlur = 4;

        ctx.fill();
        ctx.stroke();
        ctx.shadowColor = "";
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 0;
    },


    makeTextSprite: function(container, scene) {
        console.log(container.scrollWidth, container.scrollHeight)
        return window.html2canvas(container, {
            //allowTaint:true,
            scale: 1,
            width: container.scrollWidth,
            height: container.scrollHeight,
            // useCORS: true
        })
            .then(function(canvas) {
                if (!canvas) return;
                // console.log(canvas)
                /* 画布内容用于纹理贴图 */
                let texture = new THREE.Texture(canvas);
                texture.needsUpdate = true
                let spriteMaterial = new THREE.SpriteMaterial({
                    map: texture,
                    // transparent:true
                });
                let sprite = new THREE.Sprite(spriteMaterial)
                // console.log(sprite.spriteMaterial)
                /* 缩放比例 */
                sprite.scale.set(30, 20, 0)
                sprite.position.set(0, 0, 10)
                sprite.center = new THREE.Vector2(0, 0);
                scene.add(sprite);
                this.Sprites.push(sprite);
                return sprite
            });
    }

});

export default waterPipe
