<template>
  <div class="sectionBox rightBJTable" :style="{ backgroundImage: 'url(' + secBackground + ')' }">
    <h3 style="text-align: center;font-size: .9rem;
    margin-top: 0.2rem;">报警记录</h3>
    <table class="lb_boxOne">
      <thead>
        <tr>
          <td style="width: 10%; text-align: right">序号</td>
          <td style="width: 50%">报警内容</td>
          <td style="width: 25%">报警类型</td>
        </tr>
      </thead>
    </table>
    <template v-if="BJList.length > 0">
      <div id="marqure_boxBJ" class="marqure_box">
        <ul id="marqure1BJ" class="marqure1">
          <li v-for="(item, index) in BJList">
            <div class="xh" style="width: 20%">{{ index + 1 }}</div>
            <div class="wz" style="width: 50%" v-on:click="itemclick(item.WATER_METER_ID)">{{ item.NEWS_CONTENT }}</div>
            <div class="sssj" style="width: 30%">
              {{ item.NEWS_TYPE }}
            </div>
          </li>
        </ul>
        <div id="marqure2BJ" class="marqure2"></div>
      </div>
    </template>
    <template v-else>
      <div class="BJList">本周无报警记录</div>
    </template>
  </div>
</template>

<script>
import $ from "@/assets/js/jquery.min";
import { getDate, DateDiff, GMTToStrDay } from "../utils/dateUtil";

export default {
  name: "alarmRecord",
  components: {
  },
  data() {
    return {
      USER_ID: "dfe5d01baf1c485286fead0d34f805bd",
      url: "http://water.jicyun.com:8071",
      TOKEN: "b17a005fb05a4fa9b115aa04fc3ad0e9",
      secBackground: require("../assets/images/indexYSQS.16c2127c.png"),
      BJList: [],
    };
  },
  mounted() {
  },
  created() {
  },
  computed: {
    //滚动表格的配置项
    optionHover() {
      return {
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        step: 0.5, // 数值越大速度滚动越快
        openWatch: true, // 开启数据实时监控刷新dom
        // limitMoveNum: 5, //开始滚动的数据量
        // singleHeight: 0, //单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, //单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        // waitTime: 1000 //单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {
    codeInit(user) {
      console.log(user)
      this.USER_ID = user.USER_ID || this.USER_ID;
      this.url = user.ip || this.url;
      this.TOKEN = user.TOKEN || this.TOKEN;
      this.getBJList();
    },
    itemclick(waterId) {
        console.log(waterId);
      this.$emit("lookMeter", waterId);
    },
    //报警列表
    getBJList() {
      var that = this;
      $.get(
        that.url + "/appnews/list",
        {
          USER_ID: that.USER_ID,
          TOKEN: that.TOKEN,
        },
        function (data) {
          if (data.INFO != "登陆异常，请重新登陆") {
            if (data.INFO == "success") {
              let BJListobj = data;
              let newsList = [];

              data.newsList.forEach(function (val, index) {
                if (
                  DateDiff(
                    GMTToStrDay(val.CREATE_TIME),
                    getDate("yy-MM-dd", 0)
                  ) <= 7 &&
                  val.IS_DEAL != 1
                ) {
                newsList.push(val);
                }
              });
              if (newsList.length > 1 && newsList.length < 2) {
                that.BJList.push(newsList[0]);
              } else if (newsList.length >= 2) {
                console.log(newsList[0])
                console.log(newsList[1])
                that.BJList.push(newsList[0]);
                that.BJList.push(newsList[1]);
              }
            }
          } else {
            console.log('token失效')
          }
        }
      );
    },
  },
};
</script>

<style lang='less' scoped>
.sectionBox h1 {
  // background: url(../../assets/water/blue_sg.png) left 0.07rem no-repeat;
  background-size: 0.375rem 1.85rem;
  padding-left: 1.25rem;
  font-size: 1.25rem;
  margin-bottom: 1.25rem;

  img {
    width: 4rem;
    margin-left: 2.5rem;
    vertical-align: middle;
  }
}

.seamless-warp {
  height: 200px;
  overflow: hidden;

  .liStyle {
    height: 30px;
    border-bottom: 1px solid #1a67bd;
    font-size: .875rem;
    text-align: center;
    line-height: 30px;
    display: flex;
    margin: 0.5rem 2rem;

    //设置宽度与表头列宽一致
    .title1 {
      width: 10%;
    }

    .title2 {
      width: 25%;
    }

    .text_align {
      text-align: center;
    }
  }
}

.BJList {
  width: 100%;
  height: 9rem;
  line-height: 9rem;
  text-align: center;
  color: #f1f1f1;
}

.rightBJTable {
  position: absolute;
  top: 8rem;
  right: 2.5rem;
  width: 24rem;
  height: 16rem;
  background-size: 100% 100%;
  color: #f1f1f1;
  background-color: rgba(6, 19, 56, 0.66);

  table.lb_boxOne {
    width: 100%;
    /*height:2.14rem;*/
    font-size: 0.875rem;
    // background: url(../../assets/water/kx.png) no-repeat;
    // background-size: 100% 3.5rem;
    padding: 0px 1.25rem;
    margin: 1rem auto 0;
    margin: 0 auto;
  }

  table.lb_boxOne {
    table-layout: fixed;
  }

  table.lb_boxOne thead {}

  table.lb_boxOne thead tr td {
    height: 2rem;
    width: 3.125rem;
    text-align: center;
  }

  table.lb_boxOne tbody tr td {
    /*height:1.25rem;*/
    text-align: center;
    /*  border-bottom:1px solid #fff*/
  }

  .marqure1 li:first-child {
    padding-top: 1rem;
  }

  .marqure1 li {
    font-size: 0.875rem;
    text-align: center;
    overflow: hidden;
  }

  .marqure1 li div,
  .marqure2 li div {
    width: 20%;
    float: left;
  }

  .marqure_box {
    position: relative;
    font-size: .7rem;
    margin: 0px 1.375rem;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    margin: 0 auto;
  }

  .marqure1 li div.xh {
    line-height: 4.1875rem;
  }

  .marqure1 li div.sssj {
    line-height: 4.1875rem;
  }

  .marqure1 li div.wz {
    height: 5.1875rem;
    vertical-align: middle;
  }
}
</style>

